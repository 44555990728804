import React, { HTMLAttributes } from 'react'

import { css } from '@emotion/core'

import { useTheme } from 'emotion-theming'

interface TextInputProps<T> extends HTMLAttributes<T> {
  field?: {
    value: string | number
  }
  touched?: boolean
  error?: boolean
  iconName?: string
  type?: 'text' | 'email' | 'password' | 'number' | 'tel'
  disabled?: boolean
  value?: string | number | string[]
  onFocus?: () => void
  mask?: 'date'
}

const textInputStyle = (theme: ThemeType, iconName: string, hasValue: boolean) => css`
  padding: ${iconName ? '8px 16px 8px 48px' : '16px'};
  background: ${iconName ? `url('/next-assets/images/${iconName}.svg') no-repeat scroll 13px 11px` : 'none'};
  height: 48px;
  background-color: ${hasValue ? theme.neutral.white : theme.gray.gray10};
  color: ${hasValue ? theme.gray.gray60 : theme.gray.gray40};
  line-height: 1.5;
  font-size: 14px;

  width: 100%;
  border: 1px solid ${hasValue ? theme.gray.gray30 : theme.gray.gray20};
  border-radius: 4px;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:active,
  &:hover,
  &:focus-within {
    border-color: ${theme.gray.gray40};
    outline: none;
  }
  &:disabled {
    background-color: ${theme.gray.gray30};
    &:hover {
      border: 1px solid ${theme.gray.gray30};
    }
  }
  &::placeholder {
    color: ${theme.gray.gray40};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: ${theme.gray.gray60};
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-webkit-autofill::first-line {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: ${theme.gray.gray60};
    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
`

const textInputErrStyle = (theme: ThemeType) => css`
  border: solid 1px ${theme.error.red40};
  background-color: ${theme.error.red10};
  &:hover,
  &:active,
  &:focus-within {
    border: solid 1px ${theme.error.red40};
  }
  &:focus-within {
    box-shadow: 1px 1px 5px 2px ${theme.gray.gray30};
  }
  &::placeholder {
    color: ${theme.gray.gray40};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px ${theme.error.red10} inset;
  }
`

const TextInput: React.RefForwardingComponent<HTMLInputElement, TextInputProps<{}>> = (props, ref) => {
  const theme: ThemeType = useTheme()
  const { field, touched, error, type, mask, disabled, value, iconName = '', onFocus, ...otherProps } = props

  const maskVal = (typeVal: string, val: any) => {
    if (typeVal === 'date' && val) {
      return val
        .toString()
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d/]/g, '')
    }
    return val
  }
  const hasValue = Boolean(field?.value || value)
  return (
    <input
      type={type || 'text'}
      ref={ref}
      className="text-input"
      disabled={disabled || false}
      value={mask ? maskVal(mask, value) : value}
      onFocus={onFocus}
      {...field}
      {...otherProps}
      css={[textInputStyle(theme, iconName, hasValue), touched && error && textInputErrStyle(theme)]}
    />
  )
}

export default React.forwardRef(TextInput)
