import React, { FC } from 'react'
import { css } from '@emotion/core'

import { useTheme } from 'emotion-theming'
import Typography from 'components/common/atoms/Typography'

type LabelProps = {
  id?: string
  name?: string
  labelText: string
  isOptional?: boolean
  mediumMargin?: boolean
}

const labelStyle = (theme: ThemeType, mediumMargin: boolean) => css`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.gray.gray60};
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 ${mediumMargin ? '1rem' : '0.5rem'} 0;
  .label-optional {
    font-size: 12px;
  }
`

const Label: FC<LabelProps> = ({ id, name, labelText, isOptional = false, mediumMargin = false }) => {
  const theme: ThemeType = useTheme()
  return (
    <label htmlFor={id || name} css={labelStyle(theme, mediumMargin)}>
      {labelText}
      {isOptional && (
        <Typography variant="body" level="2" className="label-optional" color={theme.gray.gray50}>
          (Opsional)
        </Typography>
      )}
    </label>
  )
}

export default Label
