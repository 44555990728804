import React, { FC, useContext } from 'react'
import { css } from '@emotion/core'

import NewTypography from 'components/common/atoms/NewTypography'
import SearchResultItem from 'components/search/atoms/SearchResultItem'

import { getIconCategory } from 'components/search/searchUtils'
import SVGIcon from 'components/common/atoms/SVGIcon'
import { useTheme } from 'emotion-theming'
import { PageCtx, SearchCategory } from 'components/utils/PageProvider'

type AutocompleteResultsGroupProps = {
  resultsGroup: {
    text: string
    children: {
      id: string
      text: string
      type: string
    }[]
  }
}

const resultGroupStytle = css`
  /* padding: 12px 0; */
  .results-group {
    &-category {
      padding: 8px 16px;
    }
    @media screen and (min-width: 768px) {
      &-category {
        padding: 12px 24px;
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const AutocompleteResultsGroup: FC<AutocompleteResultsGroupProps> = ({ resultsGroup }) => {
  const theme: ThemeType = useTheme()
  const {
    state: { searchCategory },
  } = useContext(PageCtx)
  const isKampuspediaResultOnly =
    searchCategory === SearchCategory.kampuspedia && resultsGroup.text === 'campus/kampuspedia'

  return (
    <div className="autocomplete-results-group" css={resultGroupStytle}>
      {!isKampuspediaResultOnly && (
        <div className="results-group-category">
          <NewTypography variantPC="title" variant="heading" level="1" levelPC="4" color={theme.gray.gray70}>
            <div
              css={css`
                display: flex;
                align-items: center;
                color: inherit;
              `}
            >
              <SVGIcon
                name={getIconCategory(resultsGroup.text).icon}
                dataTestId={`${getIconCategory(resultsGroup.text).icon}-group-icon`}
                size="md"
                color={theme.gray.gray70}
                css={css`
                  margin-right: 16px;
                `}
              />
              {getIconCategory(resultsGroup.text).title}
            </div>
          </NewTypography>
        </div>
      )}
      <div className="results-group-list">
        {resultsGroup.children.map((result, idx) => (
          <SearchResultItem result={result} key={`search-result-item-${idx}`} />
        ))}
      </div>
    </div>
  )
}

export default AutocompleteResultsGroup
