export const lockScroll = () => {
  const { body } = document

  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
  body.style.position = 'fixed'
  body.style.top = `-${scrollY}`
  body.style.width = '100%'
}

export const unlockScroll = () => {
  const { body } = document

  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
}

// eslint-disable-next-line consistent-return
export const detectStopScroll = (callback: () => any | void) => {
  if (!callback || typeof callback !== 'function') return null

  let isScrolling: any
  window.addEventListener(
    'scroll',
    () => {
      window.clearTimeout(isScrolling)
      isScrolling = setTimeout(() => {
        callback()
      }, 60)
    },
    false
  )
  return
}
