import React, { FC } from 'react'

import { css } from '@emotion/core'
import flexCenter from 'utils/styles/flexCenter'
import Button from 'components/common/atoms/Button'

import { useFormikContext } from 'formik'
import SVGIcon from 'components/common/atoms/SVGIcon'
import LoadingSVG from 'svgs/loading.svg'

type SubmitButtonProps = {
  className?: string
  disabled?: boolean
}

const loaderStyle = css`
  ${flexCenter}
  .icon-wrapper {
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const SubmitButton: FC<SubmitButtonProps> = ({ children, className, disabled = false }) => {
  const { isSubmitting } = useFormikContext()

  return (
    <Button type="submit" variant="primary" isFullWidth isDisabled={isSubmitting || disabled} className={className}>
      {isSubmitting ? (
        <div css={loaderStyle}>
          <SVGIcon name="loading" size="xs" noFill>
            <LoadingSVG />
          </SVGIcon>
        </div>
      ) : (
        children
      )}
    </Button>
  )
}

export default SubmitButton
