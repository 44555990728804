import React from 'react'
import { css } from '@emotion/core'

type BadgeProps = {
  text: string | number
  background?: string
  color?: string
  borderColor?: string
  isRound?: boolean
}

const badgeStyle = (background?: string, color?: string, borderColor?: string, isRound?: boolean) => css`
  font-size: 10px;
  padding: 4px;
  background: ${background || '#ff5252'};
  color: ${color || '#fff'};
  border-radius: ${isRound ? '20px' : '4px'};
  border: ${borderColor ? `solid 1px ${borderColor}` : 'none'};
  font-weight: bold;
`

const Badge = ({ text, background, color, borderColor, isRound = false }: BadgeProps) => {
  return (
    <div className="badge" css={badgeStyle(background, color, borderColor, isRound)}>
      {text}
    </div>
  )
}

export default Badge
