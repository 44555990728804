import React, { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import Loading from 'svgs/small-loading.svg'

type SVGIconProps = {
  dataTestId?: string
  name: string
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  sizePC?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  color?: string
  children?: ReactNode
  noFill?: boolean
}

type SVGIconStyleParams = {
  theme: ThemeType
  size: SVGIconProps['size']
  sizePC?: SVGIconProps['sizePC']
  color?: string
  noFill?: boolean
}

const SVGIconStyle = ({ theme, size, sizePC, color, noFill }: SVGIconStyleParams) => {
  const sizes = {
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '32px',
    xl: '40px',
  }

  const sizeOnPC =
    sizePC &&
    css`
      @media screen and (min-width: 768px) {
        width: ${sizes[sizePC]};
        height: ${sizes[sizePC]};
      }
    `

  const fill = css`
    svg,
    svg path {
      fill: ${color || theme.neutral.white};
    }
  `

  return css`
    width: ${sizes[size]};
    height: ${sizes[size]};
    display: flex;
    align-items: center;
    justify-content: center;
    ${!noFill && fill}
    ${sizeOnPC}
  `
}

const LoadingPlaceholder = () => <Loading />

const SVGIcon: FC<SVGIconProps> = ({ name, size, sizePC, color, dataTestId, children, noFill, ...otherProps }) => {
  const theme: ThemeType = useTheme()
  const Icon = dynamic(() => import(`../../../../svgs/${name}.svg`), {
    loading: LoadingPlaceholder,
  })
  return (
    <div
      css={SVGIconStyle({ theme, size, sizePC, color, noFill })}
      className="icon-wrapper"
      data-testid={dataTestId || ''}
      {...otherProps}
    >
      {children ? children : <Icon />}
    </div>
  )
}

export default SVGIcon
