export const getSearchUrl = (text: string) => {
  const value = text.split('---')
  switch (value[0]) {
    case 'admission/school':
      return `/directory/${value[1]}`
    case 'province':
      return `/directory?location=${value[1]}`
    case 'city':
      return `/directory?city=${value[1]}`
    case 'career/profession':
      return `/careers/${value[1]}`
    case 'career/field':
      return `/careers?filter=${value[1]}`
    case 'campus/scholarship':
      return `/scholarships/${value[1]}`
    case 'campus/assessment':
      return `/tests/${value[1]}`
    case 'admission/study_field_campus':
      return `/directory?study_field=${value[1]}`
    case 'campus/kampuspedia':
      return `/kampuspedia/${value[1]}`
    default:
      return `/majors/${value[1]}`
  }
}

export const getIconCategory = (category: string) => {
  switch (category) {
    case 'admission/school':
      return {
        icon: 'campus-building',
        title: 'Kampus',
      }
    case 'admission/study_field':
      return {
        icon: 'major',
        title: 'Jurusan',
      }
    case 'city':
      return {
        icon: 'city',
        title: 'Kota',
      }
    case 'province':
      return {
        icon: 'province',
        title: 'Provinsi',
      }
    case 'career/profession':
      return {
        icon: 'career',
        title: 'Profesi',
      }
    case 'career/field':
      return {
        icon: 'career',
        title: 'Karir',
      }
    case 'campus/scholarship':
      return {
        icon: 'scholarship',
        title: 'Beasiswa',
      }
    case 'campus/assessment':
      return {
        icon: 'assessment',
        title: 'Uji Potensi',
      }
    case 'campus/kampuspedia':
      return {
        icon: 'kampuspedia',
        title: 'Kampuspedia',
      }
    case 'campus/kampuspedia-recommendation':
      return {
        icon: 'kampuspedia',
        title: 'Istilah Rekomendasi',
      }
    default:
      return {
        icon: 'campus-building',
        title: 'Kampus Rekomendasi',
      }
  }
}
