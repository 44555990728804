import React, { FC } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import SVGIcon from 'components/common/atoms/SVGIcon'
import flexCenter from 'utils/styles/flexCenter'

type ModalProps = {
  isShown: boolean
  maxWidth?: number
  fixedTop?: boolean
  close?: () => void
  noPadding?: boolean
}

const modalStyle = (theme: ThemeType, noPadding: boolean, maxWidth?: number, fixedTop?: boolean) => css`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 52, 79, 0.8);
    z-index: 99999;
    &__main {
      position: fixed;
      padding: ${noPadding ? '0' : '24px'};
      border-radius: 10px;
      background-color: ${theme.neutral.white};
      width: 90%;
      max-width: ${`${maxWidth || 576}px`};
      height: auto;
      top: ${fixedTop ? '25%' : '50%'};
      left: 50%;
      transform: translate(-50%, ${fixedTop ? 0 : '-50%'});
    }
    &__close {
      position: fixed;
      background-color: ${theme.neutral.white};
      border-radius: 50%;
      top: -40px;
      right: 0;
      width: 32px;
      height: 32px;
      ${flexCenter}
    }
    &__text {
      p {
        margin-bottom: 0;
      }
    }
    @media screen and (min-width: 768px) {
      &__close {
        position: fixed;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }
    }
  }

  .isShown {
    display: block;
  }

  .hide {
    display: none;
  }
`

const Modal: FC<ModalProps> = ({ isShown, children, maxWidth, close, fixedTop = false, noPadding = false }) => {
  const theme: ThemeType = useTheme()

  return (
    <div css={modalStyle(theme, noPadding, maxWidth, fixedTop)}>
      <div className={`modal ${isShown ? 'isShown' : 'hide'}`}>
        <div className="modal__main">
          {close && (
            <div className="modal__close" onClick={close} onKeyDown={close}>
              <SVGIcon name="cross" size="xs" sizePC="md" color={theme.gray.gray50} dataTestId="modal-close-icon" />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
