import React, { useContext, ChangeEvent, KeyboardEvent } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import flexCenter from 'utils/styles/flexCenter'
import sizeGenerate from 'utils/styles/sizeGenerate'

import SVGIcon from 'components/common/atoms/SVGIcon'
import CloseSVG from 'svgs/close.svg'
import SearchSVG from 'svgs/search.svg'
import ChevronSVG from 'svgs/chevron.svg'

import { SearchCtx, ActionType, SearchResultGroup } from 'components/search/SearchStateCtx'
import { getSearchUrl } from 'components/search/searchUtils'
import { PageCtx, SearchCategory } from 'components/utils/PageProvider'
import NewTypography from 'components/common/atoms/NewTypography'

const searchInputStyle = (theme: ThemeType) => css`
  position: relative;
  flex-grow: 1;
  input {
    width: 100%;
    background: ${theme.gray.gray20};
    border-radius: 4px;
    border: none;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    &::placeholder {
      color: ${theme.gray.gray40};
    }
    @media screen and (min-width: 768px) {
      padding: 12px 16px;
    }
  }
  .search-icon {
    position: absolute;
    margin: 0;
    top: 4px;
    right: 4px;
    padding: 0;
    background-color: ${theme.campus.aqua30};
    border-radius: 4px;
    ${sizeGenerate(32)}
    ${flexCenter}
  }
`

const searchInputWrapperStyle = (theme: ThemeType, isOpen: boolean) => css`
  .campus-search-category {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display: flex;

    .campus-search-category {
      display: flex;
      height: 40px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: solid 1px ${theme.gray.gray20};

      &__text {
        margin-right: 8px;
      }
      .icon-wrapper {
        transition: transform 280ms ease 0s, background-color 0.12s ease 0s;
        transform: rotate(${isOpen ? '180deg' : '0deg'});
      }
    }
    .campus-search-input-group input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`

const SearchInputGroup = () => {
  const theme: ThemeType = useTheme()
  const { state, dispatch } = useContext(SearchCtx)
  const { state: statePage } = useContext(PageCtx)

  const openAutocomplete = () => dispatch({ type: ActionType.OPEN_AUTOCOMPLETE })

  const closeAutocomplete = () => dispatch({ type: ActionType.CLOSE_AUTOCOMPLETE })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!state.isAutocompleteActive) {
      openAutocomplete()
    }
    dispatch({ type: ActionType.CHANGE_KEYWORD, payload: e.target.value })
  }

  const handleKeyup = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const results = state.searchResults as never[]
    const maxIdxOfResultItems = results.reduce((acc: number, val: SearchResultGroup) => acc + val.children.length, -1)

    switch (e.keyCode) {
      case 13: // handle enter
        if (state.selectedId) {
          window.location.href = getSearchUrl(state.selectedId)
        }
        return null
      case 27: // handle esc
        closeAutocomplete()
        return null
      case 38: // handle arrow up
        if (state.cursorIdxPosition > 0) {
          dispatch({ type: ActionType.SET_CURSOR_IDX_POSITION, payload: 'prev' })
        }
        return null
      case 40: // handle arrow down
        if (state.cursorIdxPosition < maxIdxOfResultItems) {
          dispatch({ type: ActionType.SET_CURSOR_IDX_POSITION, payload: 'next' })
        }
        return null
      default:
        return null
    }
  }

  const handleSearch = () => {
    dispatch({
      type: state.openDropdownCategory ? ActionType.CLOSE_DROPDOWN_CATEGORY : ActionType.OPEN_DROPDOWN_CATEGORY,
    })
  }

  return (
    <div
      css={
        statePage.searchCategory !== SearchCategory.default &&
        searchInputWrapperStyle(theme, state.openDropdownCategory)
      }
    >
      {statePage.searchCategory !== SearchCategory.default && (
        <div
          className="campus-search-category"
          data-testid="campus-search-category"
          onClick={handleSearch}
          onKeyDown={handleSearch}
        >
          <NewTypography
            variant="paragraph"
            level="2"
            fontWeight="600"
            color={theme.gray.gray50}
            className="campus-search-category__text"
          >
            {statePage.searchCategory === SearchCategory.kampuspedia ? 'Di Kampuspedia' : 'Di Quipper Campus'}
          </NewTypography>
          <SVGIcon name="chevron" size="xs" color={theme.gray.gray50} dataTestId="search-chevron-icon">
            <ChevronSVG />
          </SVGIcon>
        </div>
      )}
      <div className="campus-search-input-group" css={searchInputStyle(theme)}>
        <input
          type="text"
          value={state.keyword}
          placeholder={
            statePage.searchCategory === SearchCategory.kampuspedia
              ? 'Cari istilah di sini..'
              : 'Cari di Quipper Campus..'
          }
          data-testid="campus-search-input"
          onFocus={openAutocomplete}
          onKeyUp={handleKeyup}
          onChange={handleChange}
        />
        <div
          className="search-icon"
          onClick={() => state.isAutocompleteActive && closeAutocomplete()}
          onKeyDown={() => state.isAutocompleteActive && closeAutocomplete()}
          css={css`
            cursor: pointer;
          `}
        >
          <SVGIcon
            name={state.isAutocompleteActive ? 'close' : 'search'}
            size="md"
            dataTestId={state.isAutocompleteActive ? 'search-input-close-icon' : 'search-input-search-icon'}
          >
            {state.isAutocompleteActive ? <CloseSVG /> : <SearchSVG />}
          </SVGIcon>
        </div>
      </div>
    </div>
  )
}

export default SearchInputGroup
