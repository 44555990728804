import React, { useContext, useEffect, useRef, useState } from 'react'

import { css } from '@emotion/core'

import throttle from 'lodash/throttle'

import SearchInputGroup from './molecules/SearchInputGroup'
import AutocompleteResults from './organisms/AutocompleteResults'
import { SearchProvider, SearchCtx, ActionType, SearchResultGroup } from './SearchStateCtx'

import api from 'utils/api'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_KAMPUSPEDIA_SEARCH, GET_KAMPUSPEDIA_POPULAR_LIST } from '@graphql/queries/kampuspedia'
import { PageCtx, SearchCategory } from 'components/utils/PageProvider'
import DropdownCategory from './molecules/DropdownCategory'
import { triggerEvent } from 'utils/dataLayer'
import { useRouter } from 'next/router'

const campusSearchStyle = css`
  flex-grow: 1;
  @media screen and (min-width: 768px) {
    position: relative;
    margin-right: 40px;
  }
`

const Search = () => {
  const { state, dispatch } = useContext(SearchCtx)
  const {
    state: { searchCategory },
  } = useContext(PageCtx)
  const [searchKampuspedia, { data }] = useLazyQuery(GET_KAMPUSPEDIA_SEARCH)
  const [getPopListKampuspedia, { data: popListKampuspedia }] = useLazyQuery(GET_KAMPUSPEDIA_POPULAR_LIST)
  const [currentResult, setCurrentResult] = useState<SearchResultGroup[]>([])
  const router = useRouter()

  const fetchAutocomplete = async (keyword: string, category: SearchCategory) => {
    if (keyword.length > 5) {
      triggerEvent('view_search_results', { search_term: keyword, click_path: router.pathname })
    }
    if (category === SearchCategory.kampuspedia) {
      searchKampuspedia({ variables: { query: keyword } })
    } else {
      let result = []
      const response = await api('campus').get('api/autocomplete', {
        searchParams: {
          q: keyword,
          group: true,
        },
      })
      const resJson = await response.json()
      result = resJson.results

      if (category === SearchCategory.default) {
        dispatch({
          type: ActionType.UPDATE_SEARCH_RESULTS,
          payload: result,
        })
      } else {
        setCurrentResult(result)
        searchKampuspedia({ variables: { query: keyword } })
      }
    }
  }

  const throttledFetchAutocompleted = useRef(
    throttle((keyword: string, category: SearchCategory) => fetchAutocomplete(keyword, category), 1000)
  )

  const formatKampuspediaResult = (results: never[]) => {
    return results?.map((item: LooseObj) => ({
      id: `campus/kampuspedia---${item.slug}`,
      text: [item.title],
      type: 'campus/kampuspedia',
    }))
  }

  useEffect(() => {
    if (state.isAutocompleteActive && state.searchResults.length === 0) {
      searchCategory === SearchCategory.kampuspedia
        ? getPopListKampuspedia()
        : fetchAutocomplete(state.keyword, searchCategory)
    }

    if (data && searchCategory !== SearchCategory.default) {
      const kampuspediaResult = {
        text: 'campus/kampuspedia',
        children: formatKampuspediaResult(data.kampuspediaSearch),
      }

      let result = {}
      if (data.kampuspediaSearch.length === 0) {
        result = searchCategory === SearchCategory.kampuspedia ? [] : currentResult
      } else {
        result =
          searchCategory === SearchCategory.kampuspedia ? [kampuspediaResult] : [...currentResult, kampuspediaResult]
      }
      dispatch({
        type: ActionType.UPDATE_SEARCH_RESULTS,
        payload: result as never[],
      })
    }

    if (popListKampuspedia && state.keyword.length === 0) {
      const kampuspediaResult = {
        text: 'campus/kampuspedia-recommendation',
        children: formatKampuspediaResult(popListKampuspedia.kampuspediaPopularList),
      }

      dispatch({
        type: ActionType.UPDATE_SEARCH_RESULTS,
        payload: popListKampuspedia.kampuspediaPopularList.length > 0 ? ([kampuspediaResult] as never[]) : [],
      })
    }

    if (state.keyword.length > 2) throttledFetchAutocompleted.current(state.keyword, searchCategory)
  }, [state.isAutocompleteActive, data, searchCategory, state.keyword, popListKampuspedia])

  return (
    <div className="campus-search" data-testid="campus-search" css={campusSearchStyle}>
      <SearchInputGroup />
      {state.isAutocompleteActive && <AutocompleteResults />}
      {state.openDropdownCategory && <DropdownCategory />}
    </div>
  )
}

export { Search }

const SearchWithProvider = () => (
  <SearchProvider>
    <Search />
  </SearchProvider>
)

export default SearchWithProvider
