import React, { FC, useContext, ReactNode } from 'react'

import SVGIcon from 'components/common/atoms/SVGIcon'
import Typography from 'components/common/atoms/Typography'
import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import flexCenter from 'utils/styles/flexCenter'
import { ProfileCtx } from 'components/utils/ProfileProvider'

type MenuItemProps = {
  label: string
  icon: ReactNode
  iconName: string
  onClick?: (e?: any) => void
  url?: string
  isActive?: boolean
  checkTerms?: boolean
}

const menuItemStyle = (theme: ThemeType, isActive?: boolean) => css`
  ${flexCenter}
  flex-direction: column;
  cursor: pointer;
  margin: 8px;
  span {
    color: ${theme.gray.gray50};
  }
  svg,
  svg path {
    fill: ${isActive ? theme.campus.aqua30 : theme.gray.gray50};
  }
  @media screen and (min-width: 768px) {
    &:hover {
      svg,
      svg path {
        fill: ${theme.campus.aqua30};
      }
    }
    margin: 0 16px 0 0;
    height: 78px;
  }
`

const MenuItem: FC<MenuItemProps> = ({
  label,
  iconName,
  icon,
  onClick,
  url,
  isActive,
  checkTerms = false,
  ...otherProps
}) => {
  const theme: ThemeType = useTheme()
  const { state } = useContext(ProfileCtx)
  const handleClick = () => {
    if (checkTerms && state.authStatus === 'unauthorized') {
      window.location.href = `/signin?successUrl=${encodeURIComponent(window.location.pathname)}`
    } else {
      onClick && onClick()
      window.location.href = url || '#'
    }
  }
  return (
    <div className="navbar-menu-item" onClick={handleClick} onKeyDown={handleClick} css={menuItemStyle(theme, isActive)} {...otherProps}>
      <SVGIcon
        name={iconName}
        size="md"
        color={theme.gray.gray50}
        css={css`
          margin-bottom: 4px;
        `}
      >
        {icon}
      </SVGIcon>
      <Typography variant="body" level="4" fontWeight="bold" color={theme.gray.gray50}>
        {label}
      </Typography>
    </div>
  )
}

export default MenuItem
