import React, { FC } from 'react'
import ReactDOM from 'react-dom'

import { css } from '@emotion/core'
import { withTheme } from 'emotion-theming'

type BackdropProps = {
  dataTestId?: string
  parent: any
  zIndex: number
  theme: ThemeType
  onClick: () => void
  onMouseEnter?: (e?: never) => void
}

const backdropStyle = (theme: ThemeType, zIndex: number) => css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: ${zIndex};

  background-color: ${theme.gray.gray70};
  opacity: 0.8;
`

const Backdrop: FC<BackdropProps> = ({ dataTestId, theme, parent, onClick, zIndex = 2 }) =>
  ReactDOM.createPortal(
    <div className="backdrop" onClick={onClick} onKeyDown={onClick} css={backdropStyle(theme, zIndex)} data-testid={dataTestId || ''} />,
    parent
  )

export default withTheme(Backdrop)
