import React, { useState, useContext, useRef, useEffect } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import sizeGenerate from 'utils/styles/sizeGenerate'
import AssessmentHistorySVG from 'svgs/assessment-history.svg'
import HeartOutlineSVG from 'svgs/heart-outline.svg'
import MenuActiveSVG from 'svgs/directory-full.svg'
import MenuSVG from 'svgs/directory.svg'

import Container from 'components/layouts/atoms/Container'
import MenuItem from 'components/common/molecules/MenuItem'
import Search from 'components/search'
import UnauthorizedMenu from 'components/common/molecules/UnauthorizedMenu'
import Typography from 'components/common/atoms/Typography'
import PopupMenu from 'components/common/organisms/PopupMenu'

import { MENU_ITEMS } from 'utils/constants/menuItems'
import { PROFILE_MENU_ITEMS } from 'utils/constants/profileMenuItems'
import { ProfileCtx } from 'components/utils/ProfileProvider'
import { PageCtx } from 'components/utils/PageProvider'
import addEventGA from 'utils/addEventGA'
import NavbarCustom from 'components/common/molecules/NavbarCustom'
import NavbarWrapper from 'components/common/atoms/NavbarWrapper'
import NotificationMenu from 'components/notifications/organisms/NotificationMenu'
import NotificationPopover from 'components/notifications/organisms/NotificationPopover'
import { NotificationCtx, ActionType } from 'components/utils/NotificationProvider'
import StickyNavbar from 'components/directory/organisms/StickyNavbar'

const navbarStyle = css`
  .navbar {
    &-content {
      padding: 0 16px;
      height: 100%;

      display: flex;
      align-items: center;
      @media screen and (min-width: 768px) {
        padding: 0;
      }
      .desktop-menu {
        position: relative;
        display: none;
        @media screen and (min-width: 768px) {
          display: unset;
          .navbar-menu-item {
            margin: 0 40px;
          }
        }
      }
      .notification-menu {
        position: relative;
      }
    }
    &-brand {
      img {
        ${sizeGenerate(24)}
        margin-right: 16px;
        @media screen and (min-width: 768px) {
          width: unset;
          height: unset;
          margin-right: 0;
        }
      }
    }
  }
`

const mobileHiddenStyle = css`
  @media screen and (max-width: 767px) {
    display: none !important;
  }
`
const desktopHiddenStyle = css`
  @media screen and (min-width: 768px) {
    display: none !important;
  }
`
const hideBoxShadowStyle = css`
  @media screen and (min-width: 768px) {
    .navbar-wrapper {
      box-shadow: none;
    }
  }
`

const profileMenuItemStyle = (theme: ThemeType, menuState: string) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 40px;
  cursor: pointer;
  span.profile-name {
    color: ${menuState === 'profile' ? theme.campus.aqua30 : theme.gray.gray50};
  }
  &:hover {
    span.profile-name {
      color: ${theme.campus.aqua30};
    }
  }
  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
`

const Navbar = () => {
  const theme: ThemeType = useTheme()
  const [menuState, setMenuState] = useState<'idle' | 'directory' | 'profile' | 'notification'>('idle')

  const { state: profileState } = useContext(ProfileCtx)
  const { state: pageState } = useContext(PageCtx)
  const { dispatch } = useContext(NotificationCtx)

  const toggleDirectoryMenu = () => {
    setMenuState(menuState !== 'directory' ? 'directory' : 'idle')
  }
  const toggleDisplayProfileMenu = () => {
    setMenuState(menuState !== 'profile' ? 'profile' : 'idle')
  }
  const toggleNotification = () => {
    setMenuState(menuState !== 'notification' ? 'notification' : 'idle')
  }
  // for sticky navbar
  const prevScrollY = useRef(0)
  const [sticky, setSticky] = useState(false)
  const [goingUp, setGoingUp] = useState(false)
  const [sectionHeight, setSectionHeight] = useState(0)

  const updateSectionHeight = () => {
    if (typeof window !== 'undefined' && document && pageState.stickyContent?.sections) {
      const breadcrumbHeight = document.querySelector<HTMLElement>('.breadcrumb')?.offsetHeight || 0
      let sectionHeight = 0
      pageState.stickyContent?.sections?.map((section) => {
        const offsetHeight = document.querySelector<HTMLElement>(section)?.offsetHeight || 0
        sectionHeight += offsetHeight
      })
      const totalHeight = breadcrumbHeight + sectionHeight
      setSectionHeight(totalHeight)
    }
  }
  const handleScroll = () => {
    sectionHeight === 0 && updateSectionHeight()
    const currentScrollY = window.scrollY
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false)
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true)
    }
    if (sectionHeight !== 0 && currentScrollY >= sectionHeight) {
      !sticky && setSticky(true)
    } else {
      sticky && setSticky(false)
    }

    prevScrollY.current = currentScrollY
  }

  const handleNotification = () => {
    dispatch({
      type: ActionType.TOGGLE_NOTIFICATION_POPUP,
      payload: { showNotificationPopup: true },
    })
  }

  const handleProfileMenu = () => addEventGA('account', 'global', 'ga/global/button-navbar/profile')

  useEffect(() => {
    sectionHeight === 0 && updateSectionHeight()

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [goingUp, pageState.stickyContent?.firstSection, sectionHeight])

  return (
    <div className="navbar" css={[navbarStyle, sticky && hideBoxShadowStyle]}>
      <NavbarWrapper height={68}>
        <Container
          isMobileFull
          css={css`
            height: 100%;
          `}
        >
          {pageState.useCustomNavbar && (
            <NavbarCustom
              title={pageState.navbarContent?.title}
              buttonContent={pageState.navbarContent?.buttonContent}
              onClick={() => window.history.back()}
              showNavigation={pageState.navbarContent?.showNavigation}
              showMobileNotification={true}
            />
          )}
          <div className="navbar-content" css={pageState.useCustomNavbar && mobileHiddenStyle}>
            <div className="navbar-brand">
              <a href="/" onClick={() => addEventGA('navigation', 'global', 'ga/global/button-navbar/home')}>
                <picture>
                  <source srcSet="/next-assets/images/logo/quipper-campus-logo.svg" media="(min-width: 768px)" />
                  <img
                    src="/next-assets/images/logo/quipper-campus-sm-logo.svg"
                    alt="Info Kampus Terlengkap dan Berkualitas | Quipper Campus"
                  />
                </picture>
              </a>
            </div>
            <div className="desktop-menu" onMouseEnter={toggleDirectoryMenu} onMouseLeave={toggleDirectoryMenu}>
              <MenuItem
                icon={menuState === 'directory' ? <MenuActiveSVG /> : <MenuSVG />}
                iconName={menuState === 'directory' ? 'menu-active' : 'menu'}
                label="Direktori"
                data-testid="menu-directory"
                css={css`
                  margin: 0 40px !important;
                `}
                isActive={menuState === 'directory'}
                onClick={() => addEventGA('navigation', 'global', 'ga/global/button-navbar/directory')}
              />
              {menuState === 'directory' && <PopupMenu items={MENU_ITEMS} close={toggleDirectoryMenu} />}
            </div>
            <Search />
            <div
              onClick={handleNotification}
              onKeyDown={handleNotification}
              css={desktopHiddenStyle}
            >
              <NotificationMenu />
            </div>
            {profileState.authStatus === 'unauthorized' && (
              <>
                <div
                  className="notification-menu"
                  onMouseEnter={toggleNotification}
                  onMouseLeave={toggleNotification}
                  css={mobileHiddenStyle}
                >
                  <NotificationMenu isActive={menuState === 'notification'} />
                  {menuState === 'notification' && <NotificationPopover close={() => setMenuState('idle')} />}
                </div>
                <UnauthorizedMenu />
              </>
            )}
            {profileState.authStatus === 'authorized' && (
              <div
                className="authorized-menu"
                css={css`
                  display: none;
                  @media screen and (min-width: 768px) {
                    display: flex;
                  }
                `}
              >
                <MenuItem
                  icon={<AssessmentHistorySVG />}
                  iconName="assessment-history"
                  label="Riwayat Tes"
                  url="/aptitude_test/history"
                  data-testid="assessment-history-menu"
                  onClick={() => addEventGA('navigation', 'global', 'ga/global/button-navbar/history')}
                  checkTerms
                />
                <MenuItem
                  icon={<HeartOutlineSVG />}
                  iconName="heart-outline"
                  label="Favorit"
                  url="/likes/schools"
                  data-testid="favourite-menu"
                  onClick={() => addEventGA('navigation', 'global', 'ga/global/button-navbar/favorite')}
                />
                <div className="notification-menu" onMouseEnter={toggleNotification} onMouseLeave={toggleNotification}>
                  <NotificationMenu isActive={menuState === 'notification'} />
                  {menuState === 'notification' && <NotificationPopover close={() => setMenuState('idle')} />}
                </div>
                <div
                  className="profile-menu-item"
                  onMouseEnter={toggleDisplayProfileMenu}
                  onMouseLeave={toggleDisplayProfileMenu}
                  onClick={handleProfileMenu}
                  onKeyDown={handleProfileMenu}
                  css={profileMenuItemStyle(theme, menuState)}
                >
                  <img
                    src="/next-assets/images/avatar.png"
                    alt="Profile Avatar"
                    css={css`
                      width: 28px;
                      height: 28px;
                      margin-right: 12px;
                    `}
                  />
                  <Typography
                    className="profile-name"
                    variant="body"
                    level="2"
                    fontWeight="bold"
                    color={theme.gray.gray50}
                  >
                    {profileState.profile.firstName}
                  </Typography>
                  {menuState === 'profile' && (
                    <PopupMenu
                      items={[
                        {
                          url: '/profile',
                          imageUrl: '/next-assets/images/avatar.png',
                          name: profileState.profile.firstName,
                          label: 'profile',
                        },
                        ...PROFILE_MENU_ITEMS,
                      ].map((item) => ({
                        ...item,
                        onClick: () => {
                          window.location.href = item.url || '/'
                        },
                      }))}
                      close={() => setMenuState('idle')}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </NavbarWrapper>
      {pageState.useStickyNavbar && <StickyNavbar sticky={sticky}>{pageState.stickyContent?.content}</StickyNavbar>}
    </div>
  )
}

export default Navbar
