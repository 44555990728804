import React from 'react'

import { css } from '@emotion/core'
import Relazsizes from 'relazsizes'

import Button from 'components/common/atoms/Button'
import MarkdownContent from 'components/common/atoms/MarkdownContent'
import Typography from 'components/common/atoms/Typography'
import Popup from 'components/common/molecules/Popup'

import { useRouter } from 'next/router'
import { triggerEvent } from 'utils/dataLayer'

type PopupSignupProps = {
  close: () => void
  top?: number
}

const popupSignupStyle = () => css`
  .popup-signup {
    &__content {
      padding: 40px 24px;
      margin-top: 40px;
      text-align: center;
      img {
        width: 100%;
      }
      p {
        margin-top: 24px;
      }
    }
    &__button {
      display: block;
      width: 100%;
      padding: 12px 0;
      margin-top: 16px;
    }
    @media screen and (min-width: 768px) {
      &__content {
        max-width: 800px;
        margin: auto;
        padding-top: 100px;
        img {
          width: 400px;
        }
        p {
          &:nth-of-type(even) {
            margin-top: 40px;
          }
          &:nth-of-type(odd) {
            margin-top: 32px;
          }
        }
      }
      &__button {
        width: max-content;
        margin: 24px auto;
        padding: 16px 32px;
      }
    }
  }
`

const PopupSignup = ({ close, top }: PopupSignupProps) => {
  const router = useRouter()
  const signupData = {
    title: 'Yuk Daftar di Quipper Campus',
    description:
      'Selain karena daftar di Quipper Campus itu **GRATIS**, Kamu juga bisa mengenali diri kamu lebih dalam melalui **Tes Minat Bakat, Uji Potensi** hingga mendapatkan **Info Beasiswa** loh!',
    message: 'Buruan Daftar',
    link: `/signup?successUrl=${router.pathname}`,
    img: 'pop-up-signup@3x.png',
    altImg: 'Sign Up Pop-up Banner',
  }
  return (
    <Popup close={() => close()} top={top}>
      <div className="popup-signup" css={popupSignupStyle()}>
        <div className="popup-signup__content">
          <Relazsizes src={`/next-assets/images/popup-banner/${signupData.img}`} alt={signupData.altImg} />
          <Typography variant="title" level="2" tagName="p">
            {signupData.title}
          </Typography>
          <Typography variant="paragraph" level="1" tagName="span">
            <MarkdownContent content={signupData.description} />
          </Typography>
          {signupData.message && (
            <Typography variant="body" level="1" tagName="p">
              {signupData.message}
            </Typography>
          )}
          <Button
            variant="primary"
            className="popup-signup__button"
            tagName="a"
            href={signupData.link}
            onClick={() => triggerEvent('popup', { popup_action: 'register', page_location: router.pathname })}
            target="_self"
          >
            Daftar Sekarang!
          </Button>
        </div>
      </div>
    </Popup>
  )
}

export default PopupSignup
