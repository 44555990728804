import React, { ReactNode } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

type PopupContentWrapperProps = {
  children?: ReactNode
}

const popupContentWrapperStyle = (theme: ThemeType) => css`
  position: fixed;
  min-height: calc(100vh - 64px);
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  padding: 64px 0 0;
  overflow: hidden auto;
  background-color: ${theme.neutral.white};
`

const PopupContentWrapper = ({ children }: PopupContentWrapperProps) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="popup-content-wrapper" css={popupContentWrapperStyle(theme)}>
      {children}
    </div>
  )
}

export default PopupContentWrapper
