import React, { FC } from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

type Variant = 'title' | 'heading' | 'paragraph'
type Level = '1' | '2' | '3' | '4'

type NewTypographyProps = {
  tagName?: keyof JSX.IntrinsicElements
  variant: Variant
  variantPC?: Variant
  level: Level
  levelPC?: Level
  fontWeight?: 'bold' | 'bolder' | 'lighter' | 'normal' | '400' | '600' | '700'
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'unset'
  color?: string
  className?: string
}

const typographyBaseStyle = css`
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`

const titleLevel = (level: NewTypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 42px;
      `
    case '2':
      return css`
        font-size: 28px;
      `
    case '3':
      return css`
        font-size: 24px;
      `
    case '4':
      return css`
        font-size: 20px;
      `
    default:
      return css``
  }
}

const paragraphLevel = (level: NewTypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 16px;
      `
    case '2':
      return css`
        font-size: 14px;
      `
    case '3':
      return css`
        font-size: 12px;
      `
    case '4':
      return css`
        font-size: 10px;
      `
    default:
      return css``
  }
}

const typographyVariantStyle = (
  variant: NewTypographyProps['variant'],
  level: NewTypographyProps['level'],
  fontWeight?: NewTypographyProps['fontWeight']
) => {
  const weightBold = css`
    font-weight: ${fontWeight !== 'normal' ? fontWeight : 'bold'} !important;
  `
  const weightNormal = css`
    font-weight: ${fontWeight !== 'normal' ? fontWeight : 'normal'};
  `
  switch (variant) {
    case 'title':
      return css`
        ${weightBold}
        ${titleLevel(level)}
      `

    case 'heading':
      return css`
        ${weightBold}
        ${paragraphLevel(level)}
      `

    case 'paragraph':
      return css`
        ${weightNormal}
        ${paragraphLevel(level)}
        * {
          ${weightNormal}
          ${paragraphLevel(level)}
        }
      `

    default:
      return css``
  }
}

const TypographyVariantPC = (variantPC: Variant, levelPC: Level, fontWeight: NewTypographyProps['fontWeight']) => css`
  @media screen and (min-width: 768px) {
    ${typographyVariantStyle(variantPC, levelPC, fontWeight)}
  }
`

const NewTypography: FC<NewTypographyProps> = ({
  tagName = 'span',
  variant = 'paragraph',
  variantPC,
  levelPC,
  fontWeight = 'normal',
  fontStyle = 'normal',
  level = '1',
  color,
  children,
  className,
}) => {
  const theme: ThemeType = useTheme()
  const Wrapper = tagName as 'span' // ref: https://stackoverflow.com/a/55970164

  const typographyCustomStyle = css`
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
    color: ${color || theme.gray.gray70};
  `

  return (
    <Wrapper
      className={`typography ${className || ''}`}
      css={[
        typographyBaseStyle,
        typographyVariantStyle(variant, level, fontWeight),
        typographyCustomStyle,
        (variantPC || levelPC) && TypographyVariantPC(variantPC || variant, levelPC || level, fontWeight),
      ]}
    >
      {children}
    </Wrapper>
  )
}

export default NewTypography
