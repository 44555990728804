declare global {
  interface Window {
    dataLayer: {
      push: (params: LooseObj) => void
    }
  }
}

export const triggerEvent = (event: string, opts = {}) => {
  window.dataLayer.push({ event, ...opts })
}
