import React, { useEffect, useContext } from 'react'

import { css } from '@emotion/core'

import Container from 'components/layouts/atoms/Container'

import { withTheme } from 'emotion-theming'
import { ProfileCtx } from 'components/utils/ProfileProvider'
import { useRouter } from 'next/router'

type AuthPageLayoutProps = {
  theme: ThemeType
  children: React.ReactNode
}

const authPageLayoutStyle = (theme: ThemeType) => css`
  width: 100vw;
  min-height: 100vh;
  background-color: ${theme.campus.aqua30};
  padding: 40px 0 2rem 0;
  @media screen and (min-width: 768px) {
    padding: 100px 0;
  }
  .auth-page-footer {
    text-align: center;
    margin-top: 40px;
    ul > li {
      display: inline-block;
      &:not(:last-child):after {
        content: '|';
        margin: 0 8px;
        color: #fff;
      }
      & > a {
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        @media screen and (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
    p {
      font-size: 14px;
      color: #fff;
      margin-top: 1rem;
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
`

const quipperLogoStyle = css`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({ theme, children }) => {
  const { state: profileState } = useContext(ProfileCtx)
  const router = useRouter()
  useEffect(() => {
    if (profileState.authStatus === 'authorized' && router.pathname !== '/signup/application') {
      window.location.href = '/'
    }
  }, [profileState.authStatus])

  return (
    <div className="auth-page-layout" css={authPageLayoutStyle(theme)}>
      <Container isFluid={router.pathname === '/signup/application'}>
        <div className="auth-page-logo" css={quipperLogoStyle}>
          <a href="/">
            <img src="/next-assets/images/logo/quipper-campus-logo-w.svg" alt="Quipper Logo" />
          </a>
        </div>
        {children}
        <div className="auth-page-footer">
          <ul>
            <li>
              <a href="/terms">Ketentuan</a>
            </li>
            <li>
              <a href="/privacy">Kebijakan Privasi</a>
            </li>
            <li>
              <a href="mailto:quippercampus@quipper.com?subject=User%20Support:%20Inquiry_website">Kontak</a>
            </li>
          </ul>
          <p>{`©${new Date().getFullYear()} Quipper`}</p>
        </div>
      </Container>
    </div>
  )
}

export default withTheme(AuthPageLayout)
