import React, { ReactNode } from 'react'

import Container from 'components/layouts/atoms/Container'
import SVGIcon from 'components/common/atoms/SVGIcon'
import Typography from 'components/common/atoms/Typography'
import Backdrop from 'components/common/atoms/Backdrop'

import addEventGA from 'utils/addEventGA'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Badge from 'components/common/atoms/Badge'

type PopupMenuProps = {
  items: {
    url?: string
    label: string
    icon?: ReactNode
    imageUrl?: string
    name: string
    isNew?: boolean
    onClick?: () => void
    handleUrl?: () => void
  }[]
  close: (e?: never) => void
}

const popupMenuStyle = (theme: ThemeType) => css`
  position: absolute;
  width: 240px;
  z-index: 100;
  padding: 8px 0;
  top: 64px;
  right: 0;

  .popup {
    &-menu-inner {
      background-color: ${theme.neutral.white};
      box-shadow: 0 2px 12px 4px rgba(26, 52, 79, 0.2);
      border-radius: 10px;
      padding: 12px 0;
    }
    &-menu-item {
      display: flex;
      align-items: center;
      padding: 8px 0;
      justify-content: flex-start;
      width: 100%;
      cursor: pointer;
      &:hover {
        background-color: ${theme.gray.gray20};
      }
    }
    &-menu-item-wrapper {
      display: block;
      width: 100%;
    }
    &-menu-item-content {
      display: flex;
      align-items: center;
      padding: 0 16px;
      .icon-wrapper {
        margin-right: 16px;
      }
    }
  }
`

const PopupMenu = ({ items, close }: PopupMenuProps) => {
  const theme: ThemeType = useTheme()
  return (
    <>
      <div className="popup-menu" css={popupMenuStyle(theme)}>
        <div className="popup-menu-inner">
          {items.map((item, idx) => {
            const category = new RegExp(/^(signin|signup|profile)$/).test(item.label) ? 'account' : 'navigation'
            let Wrapper: keyof JSX.IntrinsicElements = 'div'
            let href = {}
            if (item.url) {
              Wrapper = 'a'
              href = { href: item.url }
            }
            return (
              <div className="popup-menu-item" key={`dir-itm-${idx}-${item.label}`}>
                <Wrapper
                  className="popup-menu-item-wrapper"
                  onClick={() => {
                    item.handleUrl ? item.handleUrl() : item.onClick && item.onClick()
                    addEventGA(category, 'global', `ga/global/menu-navbar/${item.label}`)
                  }}
                  {...href}
                >
                  <Container
                    css={css`
                      flex-grow: 1;
                    `}
                  >
                    <div className="popup-menu-item-content">
                      {item.icon && (
                        <SVGIcon name={item.label} size="md" color={theme.gray.gray70}>
                          {item.icon}
                        </SVGIcon>
                      )}
                      {!item.icon && item.imageUrl && (
                        <div
                          css={css`
                            margin-right: 16px;
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <img
                            src={item.imageUrl}
                            alt={`${item.name} icon`}
                            css={css`
                              width: 28px;
                              height: 28px;
                            `}
                          />
                        </div>
                      )}
                      <Typography
                        variant="body"
                        level="2"
                        css={css`
                          flex-grow: 1;
                        `}
                      >
                        {item.name}
                      </Typography>
                      {item.isNew && <Badge text="BARU" background={theme.error.red40} />}
                    </div>
                  </Container>
                </Wrapper>
              </div>
            )
          })}
        </div>
      </div>
      {window?.outerWidth >= 768 && document && (
        <Backdrop parent={document.body} zIndex={98} onClick={close} onMouseEnter={close} />
      )}
    </>
  )
}

export default PopupMenu
