import React, { FC } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import Backdrop from 'components/common/atoms/Backdrop'
import NotificationContent from 'components/notifications/molecules/NotificationContent'

type NotificationPopoverProps = {
  close: (e?: never) => void
}

const notificationPopoverStyle = (theme: ThemeType) => css`
  position: absolute;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  width: 400px;
  z-index: 99;
  top: 74px;
  right: -16px;
  background-color: ${theme.neutral.white};

  .notification-popover {
    &__wrapper {
      width: 400px;
      overflow: hidden auto;
      max-height: 560px;
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-thumb:hover {
        border-radius: 16px;
        box-shadow: inset 0 0 16px 16px ${theme.gray.gray30};
        border: solid 4px ${theme.gray.gray10};
      }
      &::-webkit-scrollbar {
        background-color: ${theme.gray.gray10};
        width: 16px;
      }
    }
  }
`

const NotificationPopover: FC<NotificationPopoverProps> = ({ close }) => {
  const theme: ThemeType = useTheme()

  return (
    <>
      <div className="notification-popover" css={notificationPopoverStyle(theme)}>
        <div className="notification-popover__wrapper">
          <NotificationContent />
        </div>
      </div>
      {window?.outerWidth >= 768 && document && (
        <Backdrop
          dataTestId="backdrop-notification"
          parent={document.body}
          zIndex={98}
          onClick={close}
          onMouseEnter={close}
        />
      )}
    </>
  )
}

export default NotificationPopover
