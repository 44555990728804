import React, { FC } from 'react'
import Markdown from 'react-markdown'

import { css } from '@emotion/core'

type MarkdownContentProps = {
  content: string
  className?: string
  noMargin?: boolean
}

type LinkRendererProps = { href: string }

const markdownContentStyle = (noMargin: boolean) => css`
  color: inherit;
  .campus-markdown-content {
    color: inherit;
    margin-bottom: ${noMargin ? '0' : '16px'};
    strong {
      color: inherit;
      em {
        font-weight: inherit;
      }
    }
  }
  li {
    line-height: 2;
  }
  ol + ul {
    padding-left: 60px;
  }
  ul {
    margin-left: 18px;
  }
  .truncate {
    .campus-markdown-content {
      overflow: hidden;
      position: relative;
      line-height: 1.5em;
      max-height: 4.5em;
      margin-right: 0;
      padding-right: 1em;
    }
  }
`

const LinkRenderer: FC<LinkRendererProps> = (props) => {
  const isQuipperLink = props.href.indexOf('quipper.com') >= 0
  const isQuipperCampus = props.href.indexOf('campus.quipper.com') >= 0

  const getLinkAttr = () => {
    if (isQuipperLink) {
      if (!isQuipperCampus) {
        return { target: '_blank', rel: 'noopener' }
      }
      return {}
    }
    return {
      target: '_blank',
      rel: 'nofollow noopener',
    }
  }

  return (
    <a href={props.href} {...getLinkAttr()}>
      {props.children}
    </a>
  )
}

export { LinkRenderer }

const MarkdownContent = ({ content, className, noMargin = false }: MarkdownContentProps) => (
  <>
    <Markdown
      className={`campus-markdown ${className || ''}`}
      css={markdownContentStyle(noMargin)}
      // eslint-disable-next-line react/display-name
      renderers={{ link: LinkRenderer, paragraph: (props) => <p className="campus-markdown-content" {...props} /> }}
      source={content}
      escapeHtml={false}
    />
  </>
)

export default MarkdownContent
