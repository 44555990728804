import Cookies from 'universal-cookie'
import api from 'utils/api'

export const logout = async () => {
  const cookies = new Cookies()
  const token = cookies.get(process.env.AUTH_TOKEN_COOKIE_NAME || '')
  await api('auth').post('twirp/auth.Auth/Logout', {
    json: { token },
    headers: {
      'Content-Type': 'application/json',
    },
  })
  cookies.remove(process.env.AUTH_TOKEN_COOKIE_NAME || 'learn_auth_token', {
    path: '/',
    domain: process.env.AUTH_TOKEN_COOKIE_DOMAIN || '.qall-campus-next.localhost'
  })
  location.href = '/'
}
