import React, { FC, useReducer, Dispatch } from 'react'
import flattenDeep from 'lodash/flattenDeep'

export enum ActionType {
  OPEN_AUTOCOMPLETE = 'OPEN_AUTOCOMPLETE',
  CLOSE_AUTOCOMPLETE = 'CLOSE_AUTOCOMPLETE',

  OPEN_DROPDOWN_CATEGORY = 'OPEN_DROPDOWN_CATEGORY',
  CLOSE_DROPDOWN_CATEGORY = 'CLOSE_DROPDOWN_CATEGORY',

  CHANGE_KEYWORD = 'CHANGE_KEYWORD',
  CHANGE_FETCH_STATUS = 'CHANGE_FETCH_STATUS',

  UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS',

  SET_CURSOR_IDX_POSITION = 'SET_CURSOR_IDX_POSITION',
}

export enum AsyncStatus {
  idle = 'idle',
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

type ActionVal =
  | {
      type: ActionType.OPEN_AUTOCOMPLETE
    }
  | {
      type: ActionType.CLOSE_AUTOCOMPLETE
    }
  | {
      type: ActionType.OPEN_DROPDOWN_CATEGORY
    }
  | {
      type: ActionType.CLOSE_DROPDOWN_CATEGORY
    }
  | {
      type: ActionType.CHANGE_KEYWORD
      payload: string
    }
  | {
      type: ActionType.CHANGE_FETCH_STATUS
      payload: AsyncStatus
    }
  | {
      type: ActionType.UPDATE_SEARCH_RESULTS
      payload: never[]
    }
  | {
      type: ActionType.SET_CURSOR_IDX_POSITION
      payload: 'prev' | 'next'
    }

export type SearchResultItem = {
  id: string
  text: string
  type: string
}

export type SearchResultGroup = {
  text: string
  children: SearchResultItem[]
}

interface SearchState {
  isAutocompleteActive: boolean
  openDropdownCategory: boolean
  keyword: string
  fetchStatus: AsyncStatus
  searchResults: SearchResultGroup[] | never[]
  cursorIdxPosition: number
  selectedId: string
}

const initSearchState = () => ({
  isAutocompleteActive: false,
  openDropdownCategory: false,
  keyword: '',
  fetchStatus: AsyncStatus.idle,
  searchResults: [],
  cursorIdxPosition: 0,
  selectedId: '',
})

const searchReducer = (state = initSearchState(), action: ActionVal) => {
  let newCursorIdx
  let selectedItem: SearchResultItem
  let selectedId
  let firstSearchResultGroup: SearchResultGroup

  switch (action.type) {
    case ActionType.OPEN_AUTOCOMPLETE:
      return { ...state, isAutocompleteActive: true, openDropdownCategory: false }

    case ActionType.CLOSE_AUTOCOMPLETE:
      return initSearchState()

    case ActionType.OPEN_DROPDOWN_CATEGORY:
      return { ...state, openDropdownCategory: true, isAutocompleteActive: false }

    case ActionType.CLOSE_DROPDOWN_CATEGORY:
      return { ...state, openDropdownCategory: false }

    case ActionType.CHANGE_KEYWORD:
      return { ...state, keyword: action.payload }

    case ActionType.UPDATE_SEARCH_RESULTS:
      ;[firstSearchResultGroup] = action.payload
      selectedId = firstSearchResultGroup?.children[0].id

      return { ...state, searchResults: action.payload, selectedId: selectedId }

    case ActionType.SET_CURSOR_IDX_POSITION:
      newCursorIdx = state.cursorIdxPosition + (action.payload === 'next' ? 1 : -1)
      selectedItem = flattenDeep(
        state.searchResults.map((searchResultGroup: SearchResultGroup) => [searchResultGroup.children])
      )[newCursorIdx]

      return {
        ...state,
        cursorIdxPosition: newCursorIdx,
        selectedId: selectedItem.id,
      }

    default:
      return state
  }
}

const initialSearchCtx: { state: SearchState; dispatch: Dispatch<ActionVal> } = {
  state: initSearchState(),
  dispatch: () => {
    return
  },
}

const SearchCtx = React.createContext(initialSearchCtx)

const SearchProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(searchReducer, initSearchState())

  return <SearchCtx.Provider value={{ state, dispatch }}>{children}</SearchCtx.Provider>
}

export { SearchCtx, SearchProvider }
