import React, { useContext } from 'react'

import { PageCtx, SearchCategory, ActionType as ActionTypePage } from 'components/utils/PageProvider'
import Backdrop from 'components/common/atoms/Backdrop'
import { SearchCtx, ActionType } from 'components/search/SearchStateCtx'
import { useTheme } from 'emotion-theming'
import SVGIcon from 'components/common/atoms/SVGIcon'
import CheckedSVG from 'svgs/checked.svg'
import { css } from '@emotion/core'
import NewTypography from 'components/common/atoms/NewTypography'

const SEARCH_CATEGORY = [
  { label: 'Di Kampuspedia', value: SearchCategory.kampuspedia },
  { label: 'Di Quipper Campus', value: SearchCategory.all },
]

const dropdownCategoryStyle = (theme: ThemeType) => css`
  position: absolute;
  top: 48px;
  z-index: 2;
  width: 200px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px 0;
  border-radius: 10px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: ${theme.neutral.white};
  .dropdown-category {
    &__opt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      &:hover {
        background-color: ${theme.gray.gray20};
      }
    }
  }
`

const DropdownCategory = () => {
  const { state, dispatch: dispatchPage } = useContext(PageCtx)
  const { dispatch } = useContext(SearchCtx)
  const theme: ThemeType = useTheme()
  return (
    <div className="dropdown-category" css={dropdownCategoryStyle}>
      <div className="dropdown-category__wrapper">
        {SEARCH_CATEGORY.map((opt: { label: string; value: SearchCategory }, idx: number) => (
          <div
            className="dropdown-category__opt"
            key={`category-opt-${idx}`}
            onClick={() => {
              dispatchPage({ type: ActionTypePage.SET_SEARCH_CATEGORY, payload: opt.value })
              dispatch({ type: ActionType.CLOSE_DROPDOWN_CATEGORY })
            }}
            onKeyDown={() => {
              dispatchPage({ type: ActionTypePage.SET_SEARCH_CATEGORY, payload: opt.value })
              dispatch({ type: ActionType.CLOSE_DROPDOWN_CATEGORY })
            }}
          >
            <NewTypography variant="paragraph" level="2">
              {opt.label}
            </NewTypography>
            {state.searchCategory === opt.value && (
              <SVGIcon name="checked" size="xs" color={theme.campus.aqua30} dataTestId="checked-category">
                <CheckedSVG />
              </SVGIcon>
            )}
          </div>
        ))}
      </div>
      {window?.outerWidth >= 768 && document && (
        <Backdrop
          parent={document.body}
          zIndex={98}
          dataTestId="backdrop-dropdown-category"
          onClick={() => dispatch({ type: ActionType.CLOSE_DROPDOWN_CATEGORY })}
        />
      )}
    </div>
  )
}

export default DropdownCategory
