import React, { ReactNode } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import flexCenter from 'utils/styles/flexCenter'

import SVGIcon from 'components/common/atoms/SVGIcon'
import CancelSVG from 'svgs/cancel.svg'

type PopupProps = {
  close: () => void
  top?: number
  children: ReactNode
}

const popupStyle = (theme: ThemeType, top: number) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: ${top}px;
  right: 0;

  .popup {
    &-inner {
      background-color: ${theme.neutral.white};
      padding: 24px;
      height: 100%;
    }
    &-close {
      width: 40px;
      height: 40px;
      box-shadow: 0 2px 8px 0 rgba(26, 52, 79, 0.4);
      background-color: ${theme.neutral.white};
      border-radius: 20px;
      float: right;
      cursor: pointer;
      ${flexCenter}
    }
    @media screen and (min-width: 768px) {
      &-inner {
        padding: 40px;
      }
    }
  }
`

const Popup = ({ children, close, top = 0 }: PopupProps) => {
  const theme: ThemeType = useTheme()
  return (
    <>
      <div className="popup" css={popupStyle(theme, top)}>
        <div className="popup-inner">
          <div className="popup-close" data-testid="close-popup-btn" onClick={close} onKeyDown={close}>
            <SVGIcon name="cancel" size="xs" color={theme.gray.gray70}>
              <CancelSVG />
            </SVGIcon>
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

export default Popup
