import gql from 'graphql-tag'

export const GET_KAMPUSPEDIA_INDEX = gql`
  {
    kampuspediaIndex {
      indexDetailList {
        index
        detailList {
          slug
          title
        }
      }
      pageInfo {
        total
        hasNextPage
      }
    }
  }
`

export const GET_AVAILABLE_INDEX = gql`
  {
    kampuspediaIndex {
      indexDetailList {
        index
      }
    }
  }
`

export const GET_KAMPUSPEDIA_POPULAR_LIST = gql`
  {
    kampuspediaPopularList {
      title
      slug
    }
  }
`

export const GET_KAMPUSPEDIA_DETAIL = gql`
  query QueryType($slug: String!) {
    kampuspediaDetail(slug: $slug) {
      id
      title
      slug
      content
      source
      relatedArticles
      relatedTermsDetail {
        title
        slug
      }
    }
  }
`

export const GET_KAMPUSPEDIA_SEARCH = gql`
  query QueryType($query: String!) {
    kampuspediaSearch(query: $query) {
      title
      slug
    }
  }
`

export const GET_KAMPUSPEDIA_TERM = gql`
  query QueryType($index: String!, $page: Int!) {
    kampuspediaTerm(index: $index, page: $page) {
      indexDetailList {
        index
        detailList {
          title
          slug
        }
      }
      pageInfo {
        page
        hasNextPage
        total
      }
    }
  }
`
