import { css } from '@emotion/core'

type SizeParam = number | { width: string; height: string }

const sizeGenerate = (sizeParam: SizeParam) => {
  const isSquare = typeof sizeParam === 'number'
  if (isSquare) {
    return css`
      width: ${sizeParam}px;
      height: ${sizeParam}px;
    `
  }
  return sizeParam
}

export default sizeGenerate
