import React, { FC, useReducer, Dispatch, useEffect } from 'react'

import addDays from 'date-fns/addDays'
import { useLazyQuery, ApolloProvider } from '@apollo/react-hooks'
import { GET_NOTIFICATIONS } from '@graphql/queries/notification'
import { initApolloClient } from './withApollo'

export enum ActionType {
  TOGGLE_NOTIFICATION_POPUP = 'TOGGLE_NOTIFICATION_POPUP',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_PENDING = 'SET_PENDING',
  SET_SHOW_MOBILE_NOTIFICATION = 'SET_SHOW_MOBILE_NOTIFICATION',
}

export enum NotifStatus {
  idle = 'idle',
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

type ActionVal =
  | {
      type: ActionType.TOGGLE_NOTIFICATION_POPUP
      payload: LooseObj
    }
  | {
      type: ActionType.SET_NOTIFICATIONS
      payload: LooseObj
    }
  | {
      type: ActionType.SET_PENDING
    }
  | {
      type: ActionType.SET_SHOW_MOBILE_NOTIFICATION
    }

export interface Notification {
  id: string
  title: string
  description: string
  url: string
  startDate: string
  image?: string
  unread: boolean
}

interface NotificationState {
  showNotificationPopup?: boolean
  showMobileNotification: boolean
  totalUnreadNotif: number
  notifications: Notification[]
  notifStatus: NotifStatus
}

export const initNotificationState = () => ({
  showMobileNotification: false,
  showNotificationPopup: false,
  totalUnreadNotif: 0,
  notifications: [],
  notifStatus: NotifStatus.idle,
})

const notificationReducer = (state = initNotificationState(), action: ActionVal) => {
  switch (action.type) {
    case ActionType.TOGGLE_NOTIFICATION_POPUP:
      return {
        ...state,
        showNotificationPopup: action.payload.showNotificationPopup,
      }
    case ActionType.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        totalUnreadNotif: action.payload.totalUnreadNotif,
        notifStatus: NotifStatus.completed,
      }
    case ActionType.SET_PENDING:
      return { ...state, notifStatus: NotifStatus.pending }
    case ActionType.SET_SHOW_MOBILE_NOTIFICATION:
      return { ...state, showMobileNotification: true }
    default:
      return state
  }
}

const initialNotificationCtx: { state: NotificationState; dispatch: Dispatch<ActionVal> } = {
  state: initNotificationState(),
  dispatch: () => {
    return
  },
}

export const NotificationCtx = React.createContext(initialNotificationCtx)

const NotificationProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initNotificationState())
  const [getNotif, { data, loading }] = useLazyQuery(GET_NOTIFICATIONS)

  const getUnread = (notifications: Notification[]) => {
    let unread = 0
    notifications.forEach((notif: Notification) => {
      if (notif.unread) {
        unread = unread + 1
      }
    })
    return unread
  }

  useEffect(() => {
    const currentNotif = window.localStorage.getItem('notifications') || ''
    const currentNotifTime = window.localStorage.getItem('notificationStartedAt') || ''

    if (!currentNotif || parseInt(currentNotifTime) < new Date().getTime()) {
      dispatch({ type: ActionType.SET_PENDING })
      getNotif()
    } else {
      const notifData = JSON.parse(currentNotif)
      dispatch({
        type: ActionType.SET_NOTIFICATIONS,
        payload: { notifications: notifData, totalUnreadNotif: getUnread(notifData) },
      })
    }

    if (data && !loading && state.notifStatus === NotifStatus.pending) {
      const { notifications } = data
      const notifData = notifications.map((notif: any) => {
        Reflect.deleteProperty(notif, '__typename')
        return { ...notif, image: notif.image?.url, unread: true }
      })
      dispatch({
        type: ActionType.SET_NOTIFICATIONS,
        payload: { notifications: notifData, totalUnreadNotif: notifData.length },
      })
      window.localStorage.setItem('notificationStartedAt', addDays(new Date(), 1).getTime().toString())
      window.localStorage.setItem('notifications', JSON.stringify(notifData))
    }
  }, [data, loading])

  return <NotificationCtx.Provider value={{ state, dispatch }}>{children}</NotificationCtx.Provider>
}

export { NotificationProvider }

const client = initApolloClient({}, undefined)
const NotificationWithProvider: FC = ({ children }) => (
  <ApolloProvider client={client}>
    <NotificationProvider>{children}</NotificationProvider>
  </ApolloProvider>
)

export default NotificationWithProvider
