import React from 'react'

import InterestSVG from 'svgs/interest.svg'
import LogoutSVG from 'svgs/logout.svg'
import { logout } from 'utils/logout'

export const PROFILE_MENU_ITEMS = [
  {
    icon: <InterestSVG />,
    name: 'Kampus Rekomendasi',
    url: '/recommended-campus',
    label: 'interest',
  },
  {
    icon: <LogoutSVG />,
    name: 'Keluar',
    handleUrl: () => logout(),
    label: 'logout',
  },
]
