import React, { FC, ReactNode } from 'react'
import { css } from '@emotion/core'

type ContainerProps = {
  tagName?: keyof JSX.IntrinsicElements
  isMobileFull?: boolean
  isFluid?: boolean
  children: ReactNode
}

const containerBaseStyle = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 340px;
  @media screen and (min-width: 576px) {
    max-width: 544px;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media screen and (min-width: 992px) {
    max-width: 1190px;
  }
`

const containerMobileFullStyle = css`
  max-width: 100%;
  padding: 0;
`

const containerFluidStyle = css`
  max-width: none;
`

const Container: FC<ContainerProps> = ({
  tagName = 'div',
  isMobileFull = false,
  isFluid = false,
  children,
  ...otherProps
}) => {
  const Wrapper = tagName as 'div' // ref: https://stackoverflow.com/a/55970164

  return (
    <Wrapper
      className="container"
      css={[containerBaseStyle, isMobileFull && containerMobileFullStyle, isFluid && containerFluidStyle]}
      {...otherProps}
    >
      {children}
    </Wrapper>
  )
}

export default Container
