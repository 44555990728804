import React, { FC, useReducer, Dispatch, useEffect, useState, ReactNode } from 'react'
import { useRouter } from 'next/router'

export enum SearchCategory {
  kampuspedia = 'kampuspedia',
  all = 'all', // all category + kampuspedia
  default = 'default', // all category without kampuspedia
}

export enum ActionType {
  SET_STICKY_NAVBAR = 'SET_STICKY_NAVBAR',
  SET_STICKY_PROPS = 'SET_STICKY_PROPS',
  SET_CUSTOM_NAVBAR = 'SET_CUSTOM_NAVBAR',
  RESET_PROPS = 'RESET_PROPS',
  SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU',
  SET_FULL_SCREEN = 'SET_FULL_SCREEN',
  SET_SEARCH_CATEGORY = 'SET_SEARCH_CATEGORY',
}

type ActionVal =
  | {
      type: ActionType.SET_STICKY_NAVBAR
      payload: LooseObj
    }
  | {
      type: ActionType.RESET_PROPS
    }
  | {
      type: ActionType.SET_STICKY_PROPS
      payload: LooseObj
    }
  | {
      type: ActionType.SET_CUSTOM_NAVBAR
      payload: LooseObj
    }
  | {
      type: ActionType.SHOW_MOBILE_MENU
    }
  | {
      type: ActionType.SET_FULL_SCREEN
    }
  | {
      type: ActionType.SET_SEARCH_CATEGORY
      payload: SearchCategory
    }

interface NavbarContent {
  title?: string
  buttonContent?: ReactNode
  showNavigation?: boolean
}

interface StickyContent {
  title?: string
  buttonText?: string
  link?: string
  firstSection?: string
  sections?: string[]
  content?: ReactNode
}

interface PageState {
  useStickyBottom: boolean
  stickyContent?: StickyContent
  useCustomNavbar: boolean
  useCompactFooter: boolean
  hideMobileMenu: boolean
  navbarContent?: NavbarContent
  hideNavbar: boolean
  searchCategory: SearchCategory
  useStickyNavbar: boolean
}

export const initPageState = () => ({
  useStickyBottom: false,
  useCustomNavbar: false,
  useCompactFooter: false,
  hideMobileMenu: false,
  hideNavbar: false,
  navbarContent: {
    title: '',
    buttonContent: null,
    showNavigation: true,
  },
  stickyContent: {
    title: '',
    buttonText: '',
    link: '',
    firstSection: '',
  },
  searchCategory: SearchCategory.default,
  useStickyNavbar: false,
})

const pageReducer = (state = initPageState(), action: ActionVal) => {
  switch (action.type) {
    case ActionType.SET_STICKY_NAVBAR:
      return { ...state, useStickyNavbar: true, stickyContent: action.payload.stickyContent }
    case ActionType.SET_STICKY_PROPS:
      return {
        ...state,
        useStickyBottom: true,
        stickyContent: action.payload.stickyContent,
      }
    case ActionType.SET_CUSTOM_NAVBAR:
      return { ...state, useCustomNavbar: true, hideMobileMenu: true, navbarContent: action.payload.navbarContent }
    case ActionType.SHOW_MOBILE_MENU:
      return { ...state, hideMobileMenu: false }
    case ActionType.SET_FULL_SCREEN:
      return { ...state, hideNavbar: true, hideMobileMenu: true, useCompactFooter: true }
    case ActionType.RESET_PROPS:
      return initPageState()
    case ActionType.SET_SEARCH_CATEGORY:
      return { ...state, searchCategory: action.payload }
    default:
      return state
  }
}

const initialPageCtx: { state: PageState; dispatch: Dispatch<ActionVal> } = {
  state: initPageState(),
  dispatch: () => {
    return
  },
}

const PageCtx = React.createContext(initialPageCtx)

const PageProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(pageReducer, initPageState())

  const router = useRouter()
  const { pathname } = router
  const [path, setPath] = useState(pathname)

  useEffect(() => {
    if (path !== pathname) {
      dispatch({ type: ActionType.RESET_PROPS })
      setPath(pathname)
    }
  }, [pathname])

  return <PageCtx.Provider value={{ state, dispatch }}>{children}</PageCtx.Provider>
}

export { PageCtx, PageProvider }
