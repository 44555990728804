import React, { FC } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import Container from 'components/layouts/atoms/Container'
import NewTypography from 'components/common/atoms/NewTypography'
import getLinkAttr from 'components/utils/getLinkAttr'

const footerCompactStyle = (theme: ThemeType) => css`
  background-color: ${theme.gray.gray70};
  .footer-compact {
    &__wrapper {
      padding: 16px;
    }
    &__content {
      display: flex;
      align-items: center;
      flex-direction: row;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
    &__link {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
    &__link:hover {
      text-decoration: underline;
      p {
        color: ${theme.gray.gray40};
      }
    }
    &__logo {
      margin-right: 16px;
      img {
        height: 24px;
        display: block;
      }
    }
    @media screen and (min-width: 768px) {
      &__wrapper {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__content:not(:last-of-type) {
        margin: 0;
      }
    }
  }
`

type FooterLinkProps = {
  link: string
  text: string
}

const FooterLink: FC<FooterLinkProps> = ({ link, text }) => {
  const theme: ThemeType = useTheme()
  return (
    <a href={link} className="footer-compact__link" {...getLinkAttr(link)}>
      <NewTypography variant="paragraph" level="3" tagName="p" color={theme.gray.gray40}>
        {text}
      </NewTypography>
    </a>
  )
}

const FooterCompact = () => {
  const theme: ThemeType = useTheme()
  return (
    <div className="footer-compact" css={footerCompactStyle(theme)}>
      <Container isMobileFull>
        <div className="footer-compact__wrapper">
          <div className="footer-compact__content">
            <a href="/" className="footer-compact__logo">
              <img src="/next-assets/images/logo/quipper-logo-2.svg" alt="Logo Quipper" />
            </a>
            <NewTypography
              variant="paragraph"
              level="3"
              tagName="span"
              color={theme.gray.gray40}
              className="footer-compact__copyright"
            >
              {`©${new Date().getFullYear()}. All rights reserved.`}
            </NewTypography>
          </div>
          <div className="footer-compact__content">
            <FooterLink link="https://campus.quipper.com/terms" text="Ketentuan" />
            <FooterLink link="https://campus.quipper.com/privacy" text="Kebijakan Privasi" />
            <FooterLink
              link="mailto:quippercampus@quipper.com?subject=User%20Support:%20Inquiry_website"
              text="Kontak"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FooterCompact
