import React, { useContext } from 'react'
import { css } from '@emotion/core'

import AutocompleteResultsGroup from 'components/search/molecules/AutocompleteResultsGroup'

import { SearchCtx, ActionType, SearchResultGroup } from 'components/search/SearchStateCtx'
import Backdrop from 'components/common/atoms/Backdrop'
import AutoCompleteNoResults from 'components/search/molecules/AutoCompleteNoResults'

const autocompleteResultsStyle = (noResults: boolean) => css`
  max-height: 100vh;
  width: 100vw;
  height: ${noResults ? 'calc(100vh - 57px)' : 'inherit'};
  padding-bottom: ${noResults ? '36px' : '0'};
  position: fixed;
  top: 68px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
  .autocomplete-results-wrapper {
    padding-top: 24px;
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    top: 48px;
    bottom: unset;
    max-height: 75vh;
    height: inherit;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-radius: 10px;
    box-shadow: 0 6px 16px 0 rgba(107, 143, 188, 0.1), 0 2px 8px 0 rgba(26, 52, 79, 0.4);
    z-index: 2;
    padding-bottom: 0;
    .autocomplete-results-wrapper {
      padding: 8px 0;
    }
  }
`

const AutocompleteResults = () => {
  const { state, dispatch } = useContext(SearchCtx)
  const noResults = state.searchResults.length == 0 && state.keyword.length > 2
  const results = state.searchResults as never[]
  return (
    <>
      <div
        className="autocomplete-results"
        data-testid="autocomplete-results"
        css={autocompleteResultsStyle(noResults)}
      >
        {results.length > 0 && (
          <div className="autocomplete-results-wrapper">
            {results.map((resultsGroup: SearchResultGroup, idx: number) => (
              <AutocompleteResultsGroup resultsGroup={resultsGroup} key={`results-group-${idx}`} />
            ))}
          </div>
        )}

        {noResults && <AutoCompleteNoResults />}
      </div>
      {window?.outerWidth >= 768 && document && (
        <Backdrop
          parent={document.body}
          zIndex={98}
          dataTestId="backdrop-autocomplete-results"
          onClick={() => dispatch({ type: ActionType.CLOSE_AUTOCOMPLETE })}
        />
      )}
    </>
  )
}

export default AutocompleteResults
