import React, { useContext } from 'react'

import format from 'date-fns/format'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'
import ID from 'date-fns/locale/id'

import { Notification, NotificationCtx, ActionType } from 'components/utils/NotificationProvider'
import NotificationCard from 'components/notifications/molecules/NotificationCard'
import EmptyNotification from '../EmptyNotification'
import { triggerEvent } from 'utils/dataLayer'

const NotificationContent = () => {
  const { state: notifState, dispatch } = useContext(NotificationCtx)
  const convertDate = (date: string) => {
    if (isToday(new Date(date))) {
      return 'Hari ini'
    } else if (isYesterday(new Date(date))) {
      return 'Kemarin'
    }
    return format(new Date(date), 'd MMM', { locale: ID })
  }

  const readNotif = (id: string) => {
    const newNotifications = notifState.notifications.map((notif: Notification) => {
      if (notif.id === id) {
        return { ...notif, unread: false }
      }
      return notif
    })
    window.localStorage.setItem('notifications', JSON.stringify(newNotifications))
    dispatch({
      type: ActionType.SET_NOTIFICATIONS,
      payload: { notifications: newNotifications, totalUnreadNotif: notifState.totalUnreadNotif - 1 },
    })
  }

  if (notifState.notifications.length > 0) {
    return (
      <div className="notification-content">
        {notifState.notifications.map((notif: Notification, i: number) => (
          <NotificationCard
            key={`notif-card-${i}`}
            title={notif.title}
            description={notif.description}
            startDate={convertDate(notif.startDate)}
            isActive={notif.unread}
            url={notif.url}
            image={notif.image}
            onClick={() => {
              notif.unread && readNotif(notif.id)
              triggerEvent('push_notification', { banner_name: notif.title })
            }}
          />
        ))}
      </div>
    )
  }
  return <EmptyNotification />
}

export default NotificationContent
