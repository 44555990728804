import React, { HTMLAttributes, ReactChild } from 'react'

import { css } from '@emotion/core'

import { useTheme } from 'emotion-theming'
import SVGIcon from 'components/common/atoms/SVGIcon'

interface CheckboxProps<T> extends HTMLAttributes<T> {
  field?: {}
  id?: string
  name?: string
  disabled?: boolean
  checked?: boolean
  onChange?: () => void
  onClick?: () => void
  children?: ReactChild
  className?: string
  size?: 'xs' | 'sm' | 'md'
}

const CheckboxStyle = (theme: ThemeType) => css`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px;
  color: ${theme.gray.gray60};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.gray.gray10};
    border-radius: 4px;
  }
  margin: 4px 0;
  &:last-of-type {
    margin-bottom: 0;
  }
  .checkbox-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 4px;
    background-color: ${theme.neutral.white};
    border: solid 1px ${theme.gray.gray30};
    width: 24px;
    height: 24px;
  }

  input[type='checkbox'] {
    display: none;
    &:not(:checked) + .checkbox-indicator {
      background-image: none;
    }

    &:checked:not(:disabled) + .checkbox-indicator {
      background-color: ${theme.campus.aqua30};
      border: solid 1px ${theme.campus.aqua30} !important;
    }

    &:hover {
      &:checked + .checkbox-indicator {
        border: solid 1px ${theme.campus.aqua30} !important;
      }
    }

    &:disabled + .checkbox-indicator {
      border: solid 1px ${theme.gray.gray30};
      background-color: ${theme.gray.gray20};
    }
    &:checked:disabled svg path {
      fill: ${theme.gray.gray30};
    }
  }
`

const Checkbox = (props: CheckboxProps<{}>) => {
  const theme: ThemeType = useTheme()
  const { field, disabled, checked, onClick, children, className, onChange, size = 'xs', ...otherProps } = props
  return (
    <label css={CheckboxStyle(theme)} className={className || ''}>
      <input
        type="checkbox"
        className="checkbox"
        disabled={disabled || false}
        checked={checked || false}
        onChange={onChange}
        onClick={onClick}
        {...field}
        {...otherProps}
      />
      <div className="checkbox-indicator">
        <SVGIcon
          name="checked"
          size={size}
          color={!checked ? 'none' : disabled ? theme.gray.gray30 : theme.neutral.white}
        />
      </div>
      {children}
    </label>
  )
}

export default Checkbox
