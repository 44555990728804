import React, { useState, useContext, FC } from 'react'
import { useTheme } from 'emotion-theming'
import Modal from 'components/common/molecules/Modal'
import { css } from '@emotion/core'
import NewTypography from 'components/common/atoms/NewTypography'
import Checkbox from 'components/form/atoms/Checkbox'
import Button from 'components/common/atoms/Button'
import { ProfileCtx, ActionType } from 'components/utils/ProfileProvider'
import api from 'utils/api'
import { parseCookies } from 'nookies'
import { useRouter } from 'next/router'
import { logout } from 'utils/logout'

interface TermsConditionModalProps {
  skipVpMeta?: boolean
  isShown?: boolean
  customMode?: boolean
}

const termsConditionModalStyle = (theme: ThemeType) => css`
  .tnc-modal {
    &__link {
      font-size: 14px;
      color: ${theme.campus.aqua30};
      &:hover {
        text-decoration: underline;
      }
    }
    &__check {
      padding-top: 8px;
      label {
        align-items: flex-start;
      }
      .checkbox-indicator {
        margin-right: 8px;
      }
      .checkbox-indicator,
      .icon-wrapper {
        width: 24px;
        height: 24px;
      }
    }
    &__title {
      padding: 16px 24px;
      border-bottom: 1px solid ${theme.gray.gray20};
    }
    &__content {
      padding: 24px;
      border-bottom: 1px solid ${theme.gray.gray20};
      p {
        margin-bottom: 8px;
      }
    }
    &__actions {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 8px;
      button {
        display: flex;
        align-items: center;
        margin: 0;
        height: 40px;
        padding: 12px 24px;
      }
    }
    @media screen and (min-width: 768px) {
      &__link {
        font-size: 16px;
      }
    }
  }
`

const TermsConditionModal: FC<TermsConditionModalProps> = ({
  skipVpMeta = false,
  isShown = false,
  customMode = false,
}) => {
  const theme: ThemeType = useTheme()
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const { state, dispatch, incompleteVpMeta } = useContext(ProfileCtx)
  const router = useRouter()

  const closeModal = () => dispatch({ type: ActionType.HIDE_TERMS_MODAL })

  const acceptTermsAgreement = async () => {
    const { [process.env.AUTH_TOKEN_COOKIE_NAME || 'learn_auth_token']: token } = parseCookies()
    await api('campus').post('terms/agree', {
      headers: {
        authorization: `BEARER ${decodeURIComponent(token)}`,
      },
    })

    dispatch({ type: ActionType.AGREE_TO_TERMS })
    if ((!state.hasVpMeta || incompleteVpMeta) && !skipVpMeta) {
      router.push('/signup/application')
    } else {
      closeModal()
    }
  }

  const declineTerms = () => logout()

  return (
    <Modal isShown={customMode ? isShown : state.isTermsModalShown} noPadding>
      <div className="tnc-modal" css={termsConditionModalStyle(theme)}>
        <div>
          <div className="tnc-modal__title">
            <NewTypography variant="heading" tagName="h3" level="1">
              Syarat & Ketentuan dan Kebijakan Privasi Quipper Campus
            </NewTypography>
          </div>

          <div className="tnc-modal__content">
            <NewTypography variant="paragraph" tagName="p" level="2" levelPC="1">
              Untuk melanjutkan akses ke Quipper Campus, kamu perlu membaca dan menyetujui{' '}
              <a href="/terms" target="_blank" rel="noopener" className="tnc-modal__link">
                Syarat dan Ketentuan
              </a>{' '}
              dan{' '}
              <a href="/privacy" target="_blank" rel="noopener" className="tnc-modal__link">
                Kebijakan Privasi
              </a>{' '}
              kami.
            </NewTypography>
            <NewTypography variant="paragraph" tagName="p" level="2" levelPC="1">
              Jika kamu tidak menyetujui Syarat & Ketentuan dan Kebijakan Privasi, klik Tolak dan sistem akan keluar
              otomatis.
            </NewTypography>

            <div className="tnc-modal__check">
              <Checkbox
                id="campus-terms-agreement"
                name="campus-terms-agreement"
                checked={isTermsAccepted}
                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
              >
                <NewTypography tagName="span" variant="paragraph" level="2" levelPC="1">
                  Kamu telah membaca, mengerti, dan menyetujui Syarat & Ketentuan dan Kebijakan Privasi Quipper Campus.
                  Persetujuan ini harus diberikan oleh orang tua dan/atau wali jika kamu masih di bawah umur.
                </NewTypography>
              </Checkbox>
            </div>
          </div>
        </div>

        <div className="tnc-modal__actions">
          <Button kind="small" type="button" variant="secondary" onClick={declineTerms}>
            Tolak
          </Button>
          <Button
            kind="small"
            type="button"
            variant="primary"
            onClick={acceptTermsAgreement}
            isDisabled={!isTermsAccepted}
          >
            Setuju
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TermsConditionModal
