import React, { FC, useReducer, Dispatch, Reducer, useEffect } from 'react'
import api from 'utils/api'

import Cookies from 'universal-cookie'

export enum ActionType {
  SET_PROFILE = 'SET_PROFILE',
  SET_AUTHORIZATION_STATUS = 'SET_AUTHORIZATION_STATUS',
  SET_ASYNC_STATUS = 'SET_ASYNC_STATUS',
  SHOW_TERMS_MODAL = 'SHOW_TERMS_MODAL',
  HIDE_TERMS_MODAL = 'HIDE_TERMS_MODAL',
  AGREE_TO_TERMS = 'AGREE_TO_TERMS',
}

export enum AsyncStatus {
  idle = 'idle',
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
}

export enum AuthorizationStatus {
  idle = 'idle',
  authorized = 'authorized',
  unauthorized = 'unauthorized',
}

type ActionVal =
  | {
      type: ActionType.SET_PROFILE
      payload: LooseObj
    }
  | {
      type: ActionType.SET_AUTHORIZATION_STATUS
      payload: AuthorizationStatus
    }
  | {
      type: ActionType.SET_ASYNC_STATUS
      payload: AsyncStatus
    }
  | {
      type: ActionType.SHOW_TERMS_MODAL
    }
  | {
      type: ActionType.HIDE_TERMS_MODAL
    }
  | {
      type: ActionType.AGREE_TO_TERMS
    }

interface ProfileState {
  authStatus: AuthorizationStatus
  isFetching: AsyncStatus
  profile: {
    username: string
    firstName: string
    lastName: string
    gradeLevel: string
    hasChosenCollege: boolean | null
  }
  hasVpMeta: boolean
  agreedToTerms: boolean
  agreedToPolicy: boolean
  isTermsModalShown: boolean
}

const initProfileState = () => ({
  profile: {
    username: '',
    firstName: '',
    lastName: '',
    gradeLevel: '',
    hasChosenCollege: null,
  },
  authStatus: AuthorizationStatus.idle,
  isFetching: AsyncStatus.idle,
  hasVpMeta: false,
  agreedToTerms: false,
  agreedToPolicy: false,
  isTermsModalShown: false,
})

const profileReducer = (state = initProfileState(), action: ActionVal) => {
  switch (action.type) {
    case ActionType.SET_AUTHORIZATION_STATUS:
      return {
        ...state,
        authStatus: action.payload,
      }
    case ActionType.SET_ASYNC_STATUS:
      return {
        ...state,
        isFetching: action.payload,
      }
    case ActionType.SET_PROFILE:
      return {
        ...state,
        isFetching: AsyncStatus.completed,
        profile: action.payload.profile,
        hasVpMeta: action.payload.hasVpMeta,
        agreedToTerms: action.payload.agreedToTerms,
        agreedToPolicy: action.payload.agreedToPolicy,
      }
    case ActionType.SHOW_TERMS_MODAL:
      return {
        ...state,
        isTermsModalShown: true,
      }
    case ActionType.HIDE_TERMS_MODAL:
      return {
        ...state,
        isTermsModalShown: false,
      }
    case ActionType.AGREE_TO_TERMS:
      return {
        ...state,
        agreedToTerms: true,
        agreedToPolicy: true,
      }
    default:
      return state
  }
}

export const initialProfileCtx: {
  state: ProfileState
  dispatch: Dispatch<ActionVal>
  getProfile: () => Promise<void>
  incompleteVpMeta: boolean
} = {
  state: initProfileState(),
  dispatch: () => {
    return
  },
  getProfile: async () => {
    return
  },
  incompleteVpMeta: false,
}

const ProfileCtx = React.createContext(initialProfileCtx)

const ProfileProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<any, any>>(profileReducer, initProfileState())

  const getProfile = async () => {
    const cookies = new Cookies()
    const token = cookies.get(process.env.AUTH_TOKEN_COOKIE_NAME || '')

    if (token) {
      try {
        const profileResponse: LooseObj = await api('campus').get('api/profile', {
          headers: {
            authorization: `BEARER ${decodeURIComponent(token)}`,
          },
        })

        const profileJson = await profileResponse.json()
        const {
          profile: {
            first_name: firstName,
            last_name: lastName,
            username,
            agreed_to_campus_terms: agreedToTerms,
            agreed_to_campus_policy: agreedToPolicy,
            grade_level: gradeLevel,
            has_chosen_college: hasChosenCollege,
          },
          hasVpMeta,
        } = profileJson

        dispatch({ type: ActionType.SET_AUTHORIZATION_STATUS, payload: AuthorizationStatus.authorized })
        dispatch({
          type: ActionType.SET_PROFILE,
          payload: {
            profile: { firstName, lastName, username, gradeLevel, hasChosenCollege },
            hasVpMeta,
            agreedToTerms,
            agreedToPolicy,
          },
        })
        dispatch({ type: ActionType.SET_ASYNC_STATUS, payload: AsyncStatus.completed })
      } catch (error) {
        dispatch({ type: ActionType.SET_AUTHORIZATION_STATUS, payload: AuthorizationStatus.unauthorized })
        dispatch({ type: ActionType.SET_ASYNC_STATUS, payload: AsyncStatus.failed })
      }
    } else {
      dispatch({ type: ActionType.SET_AUTHORIZATION_STATUS, payload: AuthorizationStatus.unauthorized })
      dispatch({ type: ActionType.SET_ASYNC_STATUS, payload: AsyncStatus.completed })
    }
  }

  const incompleteVpMeta =
    state.hasVpMeta && state.profile.gradeLevel === 'over_g12' && state.profile.hasChosenCollege === null

  useEffect(() => {
    getProfile()
  }, [])

  return <ProfileCtx.Provider value={{ state, dispatch, getProfile, incompleteVpMeta }}>{children}</ProfileCtx.Provider>
}

export { ProfileCtx, ProfileProvider }
