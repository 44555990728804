export const apiClientOptions = {
  clientId: process.env.CLIENT_ID,
  clientSecret: process.env.CLIENT_SECRET,
  responseType: 'json',
  ssrMode: !process.browser,
  isProduction: process.env.NODE_ENV !== 'production',
}

type TFetchOpts = { json?: LooseObj; searchParams?: LooseObj; headers?: LooseObj }

class Api {
  host: string
  constructor(params: { host: 'qs' | 'campus' | 'auth' }) {
    this.host = params.host
  }

  get prefixUrl() {
    if (this.host === 'auth') {
      return (process.env.CAMPUS_SERVER_HOST || '').replace('/qs-campus-server', '')
    }
    return this.host === 'campus' ? process.env.CAMPUS_SERVER_HOST : process.env.QUIPPER_API_HOST
  }

  requestUrl(path?: string, searchParams?: LooseObj) {
    const url = new URL(`${this.prefixUrl}/${path}`)
    searchParams && Object.keys(searchParams).forEach((key) => url.searchParams.append(key, searchParams[key]))
    return url.href
  }

  get(path: string, options?: TFetchOpts) {
    return fetch(this.requestUrl(path, options?.searchParams), {
      method: 'get',
      headers: options?.headers,
    })
  }

  post(path: string, options?: TFetchOpts) {
    return fetch(this.requestUrl(path, options?.searchParams), {
      method: 'post',
      headers: options?.headers,
      body: options?.json && JSON.stringify(options?.json),
    })
  }

  put(path: string, options?: TFetchOpts) {
    return fetch(this.requestUrl(path, options?.searchParams), {
      method: 'put',
      headers: options?.headers,
      body: options?.json && JSON.stringify(options?.json),
    })
  }

  delete(path: string, options?: TFetchOpts) {
    return fetch(this.requestUrl(path, options?.searchParams), {
      method: 'delete',
      headers: options?.headers,
      body: options?.json && JSON.stringify(options?.json),
    })
  }
}

export const campusSSRApi = (path: string, options?: TFetchOpts) => {
  if (apiClientOptions.ssrMode) {
    const prefixUrl = apiClientOptions.isProduction ? 'http://campus-server:3000' : 'http://campus-server'
    return fetch(`${prefixUrl}/${path}`, {
      method: 'get',
      headers: options?.headers,
    })
  } else {
    const prefixUrl = apiClientOptions.isProduction
      ? 'http://qall-campus-next-dev-proxy.localhost/qs-campus-server'
      : process.env.CAMPUS_SERVER_HOST
    return fetch(`${prefixUrl}/${path}`, {
      method: 'get',
      headers: options?.headers,
    })
  }
}

export default (host?: 'qs' | 'campus' | 'auth') => {
  return new Api({ host: host || 'qs' })
}
