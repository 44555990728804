import React from 'react'

import CampusBuildingSVG from 'svgs/campus-building.svg'
import MajorSVG from 'svgs/major.svg'
import CareerSVG from 'svgs/career.svg'
import ScholarshipSVG from 'svgs/scholarship.svg'
import AssessmentSVG from 'svgs/assessment.svg'
import KampuspediaSVG from 'svgs/kampuspedia.svg'
import LearnSVG from 'svgs/learn.svg'

export const MENU_ITEMS = [
  {
    icon: <CampusBuildingSVG />,
    name: 'Kampus',
    url: '/directory',
    label: 'campus',
  },
  {
    icon: <MajorSVG />,
    name: 'Jurusan',
    url: '/majors',
    label: 'major',
  },
  {
    icon: <CareerSVG />,
    name: 'Karir',
    url: '/careers',
    label: 'career',
  },
  {
    icon: <ScholarshipSVG />,
    name: 'Beasiswa',
    url: '/scholarships',
    label: 'scholarship',
  },
  {
    icon: <AssessmentSVG />,
    name: 'Uji Potensi',
    url: '/tests',
    label: 'test',
  },
  {
    icon: <KampuspediaSVG />,
    name: 'Kampuspedia',
    url: '/kampuspedia',
    label: 'kampuspedia',
    isNew: true,
  },
  {
    icon: <LearnSVG />,
    name: 'Belajar',
    url: 'https://learn.quipper.com',
    label: 'learn',
  },
]
