import gql from 'graphql-tag'

export const GET_NOTIFICATIONS = gql`
  query QueryType {
    notifications {
      id
      title
      description
      url
      startDate
      image {
        url(size: "24x24")
      }
    }
  }
`
