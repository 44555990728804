import React, { useContext } from 'react'

import Relazsizes from 'relazsizes'
import { css } from '@emotion/core'
import NewTypography from 'components/common/atoms/NewTypography'
import Button from 'components/common/atoms/Button'
import { SearchCtx, ActionType } from 'components/search/SearchStateCtx'

const submitMessageStyle = css`
  text-align: center;
  .submit-message {
    &__img {
      width: 140px;
      height: 88px;
      margin: 8px auto 40px;
      display: block;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &__text {
      margin: 16px 0 40px;
    }
    &__btn {
      font-size: 14px;
      line-height: 16px;
      padding: 12px 24px;
      margin: 0;
    }
    @media screen and (min-width: 768px) {
      &__img {
        margin-top: 0;
      }
      &__btn {
        width: inherit;
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }
`

const SubmitMessage = () => {
  const { dispatch } = useContext(SearchCtx)
  return (
    <div className="submit-message" css={submitMessageStyle}>
      <Relazsizes
        src="/next-assets/images/skeleton.svg"
        dataSrc="/next-assets/images/search/submitted_msg.png"
        alt="Search Submitted Message"
        className="submit-message__img"
      />
      <NewTypography tagName="h4" variant="heading" level="1">
        Terima kasih telah membantu Quipper Campus!
      </NewTypography>
      <NewTypography tagName="p" variant="paragraph" level="2" levelPC="1" className="submit-message__text">
        Masukkan dari teman-teman sangat kami butuhkan agar Quipper Campus menjadi lebih baik lagi
      </NewTypography>
      <Button
        variant="primary"
        onClick={() => dispatch({ type: ActionType.CLOSE_AUTOCOMPLETE })}
        className="submit-message__btn"
        isFullWidth
      >
        Tutup
      </Button>
    </div>
  )
}

export default SubmitMessage
