import React, { HTMLAttributes, ReactNode } from 'react'

import Label from 'components/form/atoms/Label'
import InfoMessage from 'components/form/atoms/InfoMessage'

import { useField } from 'formik'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

interface FormGroupProps extends HTMLAttributes<{}> {
  id: string
  name: string
  label?: string
  labelIsOptional?: boolean
  type?: string
  disabled?: boolean
  notes?: string
  value?: string | number | string[]
  renderInputField: (props: {}) => ReactNode
}

const FormGroupStyle = css`
  text-align: left;
`

const FormGroup = (props: FormGroupProps) => {
  const theme: ThemeType = useTheme()
  //destructure props, remove label, and create new object withour label prop as Input Props
  const { label = '', labelIsOptional = false, renderInputField, notes, ...inputProps } = props

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(inputProps)
  const message = () => {
    if (notes && !(meta.touched && meta.error)) {
      return <InfoMessage text={notes} color={theme.gray.gray50} />
    } else if (meta.touched && meta.error) {
      return <InfoMessage text={meta.error} color={theme.error.red40} />
    }
    return null
  }

  return (
    <div className="form-group" css={FormGroupStyle}>
      {label && <Label id={props.id} name={props.name} labelText={label} isOptional={labelIsOptional} />}
      {renderInputField({
        field,
        label,
        touched: meta.touched,
        error: !!meta.error,
        disabled: false,
        ...inputProps,
      })}
      {message()}
    </div>
  )
}

export default FormGroup
