import React, { FC, useContext } from 'react'

import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'
import flexCenter from 'utils/styles/flexCenter'
import BellSVG from 'svgs/bell.svg'
import BellActiveSVG from 'svgs/bell-full.svg'

import { NotificationCtx } from 'components/utils/NotificationProvider'
import Badge from 'components/common/atoms/Badge'
import SVGIcon from 'components/common/atoms/SVGIcon'
import Typography from 'components/common/atoms/Typography'

type NotificationMenuProps = {
  isActive?: boolean
}

const notificationMenuStyle = css`
  position: relative;
  ${flexCenter}
  flex-direction: column;
  cursor: pointer;
  height: 78px;
  margin-left: 16px;
  .notification-menu {
    &__icon {
      position: relative;
      .badge {
        position: absolute;
        top: -4px;
        right: -5px;
        padding: 0.5px 6px;
      }
    }
    &__label {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    margin-left: 0;
    .notification-menu__label {
      display: block;
    }
  }
`

const NotificationMenu: FC<NotificationMenuProps> = ({ isActive }) => {
  const theme: ThemeType = useTheme()
  const { state: notifState } = useContext(NotificationCtx)
  return (
    <div className="notification-menu" css={notificationMenuStyle}>
      <div className="notification-menu__icon">
        <SVGIcon
          dataTestId="notification-icon"
          name="bell"
          size="md"
          color={isActive ? theme.campus.aqua30 : theme.gray.gray50}
          css={css`
            margin-bottom: 4px;
          `}
        >
          {isActive ? <BellActiveSVG /> : <BellSVG />}
        </SVGIcon>
        {notifState.totalUnreadNotif > 0 && (
          <Badge text={notifState.totalUnreadNotif} background={theme.error.red40} isRound />
        )}
      </div>
      <Typography
        className="notification-menu__label"
        variant="body"
        level="4"
        fontWeight="bold"
        color={isActive ? theme.campus.aqua30 : theme.gray.gray50}
      >
        Notifikasi
      </Typography>
    </div>
  )
}

export default NotificationMenu
