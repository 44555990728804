import React, { ReactNode } from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import RightSVG from 'svgs/right.svg'
import Container from 'components/layouts/atoms/Container'
import SVGIcon from 'components/common/atoms/SVGIcon'
import Typography from 'components/common/atoms/Typography'
import Badge from 'components/common/atoms/Badge'
import addEventGA from 'utils/addEventGA'

type MobileFullscreenMenuProps = {
  items: {
    url?: string
    icon?: ReactNode
    imageUrl?: string
    name: string
    label: string
    isNew?: boolean
    handleUrl?: () => void
  }[]
}

const mobileFullscreenMenuStyle = (theme: ThemeType) => css`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  padding-top: 64px;
  overflow: scroll;
  .directory {
    &-menu-item {
      display: flex;
      align-items: center;
      padding: 12px 0;
      justify-content: flex-start;
      width: 100%;
      border-bottom: solid 1px ${theme.gray.gray30};
    }
    &-menu-item-wrapper {
      display: block;
      width: 100%;
    }
    &-menu-item-content {
      display: flex;
      align-items: center;
    }
  }
  .badge {
    margin-right: 16px;
  }
`

const MobileFullscreenMenu = ({ items }: MobileFullscreenMenuProps) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="directory-menu" css={mobileFullscreenMenuStyle(theme)}>
      {items.map((item, idx) => {
        const category = new RegExp(/^(signin|signup|profile)$/).test(item.label) ? 'account' : 'navigation'
        const handleClick = (e: any) => {
          addEventGA(category, 'global', `ga/global/button-navbar/${item.label}`)
          if (item.handleUrl) {
            item.handleUrl()
            e.preventDefault()
          }
        }
        let Wrapper: keyof JSX.IntrinsicElements = 'div'
        let href = {}
        if (item.url) {
          Wrapper = 'a'
          href = { href: item.url }
        }
        return (
          <div className="directory-menu-item" key={`dir-itm-${idx}`}>
            <Wrapper onClick={handleClick} className="directory-menu-item-wrapper" {...href}>
              <Container
                isFluid
                css={css`
                  flex-grow: 1;
                  padding: 0 16px;
                `}
              >
                <div className="directory-menu-item-content">
                  {item.icon && (
                    <SVGIcon
                      css={css`
                        margin-right: 16px;
                      `}
                      name={item.label}
                      size="md"
                      color={theme.gray.gray70}
                    >
                      {item.icon}
                    </SVGIcon>
                  )}
                  {!item.icon && item.imageUrl && (
                    <div
                      css={css`
                        margin-right: 16px;
                      `}
                    >
                      <img
                        src={item.imageUrl}
                        alt="Profile Avatar Icon"
                        css={css`
                          width: 24px;
                          height: 24px;
                        `}
                      />
                    </div>
                  )}
                  <Typography
                    variant="body"
                    level="1"
                    css={css`
                      flex-grow: 1;
                    `}
                  >
                    {item.name}
                  </Typography>
                  {item.isNew && <Badge text="BARU" background={theme.error.red40} />}
                  <SVGIcon name="right" size="md" color={theme.gray.gray70}>
                    <RightSVG />
                  </SVGIcon>
                </div>
              </Container>
            </Wrapper>
          </div>
        )
      })}
    </div>
  )
}

export default MobileFullscreenMenu
