import React, { FC } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import Relazsizes from 'relazsizes'

import Container from 'components/layouts/atoms/Container'
import Typography from 'components/common/atoms/Typography'
import SVGIcon from 'components/common/atoms/SVGIcon'
import Separator from 'components/common/atoms/Separator'
import getLinkAttr from 'components/utils/getLinkAttr'

const footerStyle = (theme: ThemeType) => css`
  background-color: ${theme.gray.gray70};
  margin-bottom: 56px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
  .footer {
    &__tagline,
    &__col:not(:first-of-type) {
      margin-left: 100px;
    }
    &__tagline {
      flex-grow: 1;
    }
    &__media {
      display: flex;
    }
    &__media-item {
      border-radius: 50%;
      padding: 8px;
      &:first-of-type {
        background-color: #8134af;
      }
      &:nth-of-type(2) {
        background-color: #1877f2;
      }
      &:nth-of-type(3) {
        background-color: #1da1f2;
      }
      &:last-of-type {
        background-color: #ff0000;
      }
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
    &__wrapper {
      padding: 40px 0;
      &:first-of-type {
        align-items: center;
      }
    }
    &__item-icon,
    &__bottom-content {
      display: flex;
      align-items: center;
    }
    &__wrapper,
    &__row {
      display: flex;
      justify-content: space-between;
    }
    &__link-item:nth-of-type(2),
    &__title:nth-of-type(2) {
      margin-top: 40px;
    }
    &__address,
    &__link:not(:last-child) {
      margin-bottom: 16px;
      display: block;
    }
    &__title,
    &__item-extra,
    &__item-icon:not(:last-child) {
      margin-bottom: 24px;
    }
    &__item-extra {
      padding-left: 40px;
      margin-top: -16px;
    }
    &__icon {
      margin-right: 16px;
    }
    &__bottom-content .footer__link {
      margin: 0 0 0 24px;
    }
    &__link > p,
    &__item-extra > p,
    &__link,
    &__address,
    &__copyright {
      font-size: 12px;
      line-height: 18px;
    }
    &__link:hover {
      text-decoration: underline;
      color: ${theme.gray.gray30};
      p {
        color: ${theme.gray.gray30};
      }
    }
    @media screen and (max-width: 767px) {
      &__tagline {
        padding: 40px 0;
      }
      &__tagline {
        margin-left: 0;
      }
      &__media-item:not(:last-of-type) {
        margin-right: 32px;
      }
      &__wrapper {
        text-align: center;
      }
      &__col,
      &__wrapper,
      &__row,
      &__bottom-content,
      &__item-icon {
        flex-direction: column;
      }
      &__col {
        margin: 0 0 40px 0 !important;
      }
      &__item {
        margin: 0;
      }
      &__wrapper:last-of-type {
        flex-direction: column-reverse;
      }
      &__bottom-content .footer__link {
        margin: 0 0 24px 0;
      }
      &__item-extra {
        padding: 0;
      }
      &__icon {
        margin: 0 0 8px 0;
      }
      &__title {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`
type FooterTitleProps = {
  title: string
}

const FooterTitle: FC<FooterTitleProps> = ({ title }) => {
  const theme: ThemeType = useTheme()
  return (
    <Typography
      variant="paragraph"
      level="2"
      tagName="p"
      color={theme.neutral.white}
      fontWeight="bold"
      className="footer__title"
    >
      {title}
    </Typography>
  )
}

type FooterLinkProps = {
  link: string
  text: string
}

const FooterLink: FC<FooterLinkProps> = ({ link, text }) => {
  const theme: ThemeType = useTheme()
  return (
    <a href={link} className="footer__link" {...getLinkAttr(link)}>
      <Typography variant="paragraph" level="2" tagName="p" color={theme.gray.gray40}>
        {text}
      </Typography>
    </a>
  )
}

const Footer = () => {
  const theme: ThemeType = useTheme()
  return (
    <div className="footer" css={footerStyle(theme)}>
      <Container>
        <div className="footer__wrapper">
          <Relazsizes dataSrc="/next-assets/images/logo/quipper-main-logo.svg" alt="Quipper Logo" />
          <Typography variant="paragraph" level="1" color={theme.gray.gray40} className="footer__tagline">
            Panduan memilih kampus, jurusan dan karir untuk masa depanmu.
          </Typography>
          <div className="footer__media">
            <a href="https://www.instagram.com/quippercampus/" className="footer__media-item">
              <SVGIcon name="instagram" color={theme.neutral.white} size="xs" />
            </a>
            <a href="https://www.facebook.com/quippercampus/" className="footer__media-item">
              <SVGIcon name="facebook" color={theme.neutral.white} size="xs" />
            </a>
            <a href="https://twitter.com/quippercampus" className="footer__media-item">
              <SVGIcon name="twitter" color={theme.neutral.white} size="xs" />
            </a>
            <a href="https://www.youtube.com/c/QuipperVideoID/" className="footer__media-item">
              <SVGIcon name="youtube" color={theme.neutral.white} size="xs" />
            </a>
          </div>
        </div>
        <Separator color={theme.gray.gray60} noMargin />
        <div className="footer__wrapper">
          <div className="footer__row">
            <div className="footer__col">
              <FooterTitle title="Quipper Campus" />
              <FooterLink link="https://campus.quipper.com/directory" text="Direktori" />
              <FooterLink link="https://campus.quipper.com/majors" text="Jurusan" />
              <FooterLink link="https://campus.quipper.com/careers" text="Karir" />
              <FooterLink link="https://campus.quipper.com/tests" text="Uji Potensi" />
              <FooterLink link="https://campus.quipper.com/partners" text="Mitra" />
            </div>
            <div className="footer__col">
              <div className="footer__link-item">
                <FooterTitle title="Untuk Murid" />
                <FooterLink link="https://www.quipper.com/id/video/" text="Quipper Video" />
                <FooterLink link="https://www.quipper.com/id/school/students" text="Quipper School" />
                <FooterLink link="https://campus.quipper.com/" text="Quipper Campus" />
              </div>
              <div className="footer__link-item">
                <FooterTitle title="Untuk Guru" />
                <FooterLink link="https://www.quipper.com/id/school/teachers" text="Quipper School" />
              </div>
            </div>
            <div className="footer__col">
              <FooterTitle title="Tentang Quipper" />
              <FooterLink link="https://www.quipper.com/id/about/" text="Quipper Indonesia" />
              <FooterLink link="https://www.quipper.com/career/Indonesia/" text="Karir" />
              <FooterLink link="https://www.quipper.com/id/blog/category/media-corner/" text="Media" />
              <FooterLink link="https://www.quipper.com/id/blog" text="Quipper Blog" />
              <FooterLink link="https://quipper.github.io" text="Tech Blog" />
            </div>
          </div>
          <div className="footer__item">
            <FooterTitle title="PT Quipper Edukasi Indonesia" />
            <Typography variant="body" level="2" tagName="p" color={theme.gray.gray40} className="footer__address">
              Gedung Wirausaha, 7th floor <br /> Jalan H.R. Rasuna Said Kav. C5 <br /> Setiabudi, Jakarta, Indonesia
              12920
            </Typography>
            <div className="footer__item-icon">
              <div className="footer__icon">
                <SVGIcon name="mail-square" size="md" color={theme.gray.gray40} />
              </div>
              <FooterLink
                link="mailto:quippercampus@quipper.com?subject=User%20Support:%20Inquiry_website"
                text="quippercampus@quipper.com"
              />
            </div>
            <div className="footer__item-icon">
              <div className="footer__icon">
                <SVGIcon name="whatsapp" size="md" color={theme.gray.gray40} />
              </div>
              <div>
                <FooterLink link="https://wa.me/6285157377153" text="085157377153" />
              </div>
            </div>
            <div className="footer__item-extra">
              <Typography variant="body" level="2" tagName="p" color={theme.gray.gray40}>
                (09.00 - 18.00)
              </Typography>
            </div>
            <Typography
              variant="body"
              level="2"
              tagName="p"
              font-weight="bold"
              color={theme.gray.gray40}
              className="footer__title"
            >
              Customer Service
            </Typography>
            <div className="footer__item-icon">
              <div className="footer__icon">
                <SVGIcon name="call-center" size="md" color={theme.gray.gray40} />
              </div>
              <div>
                <FooterLink link="tel:+62-081197905700" text="0811 9790 5700" />
              </div>
            </div>
            <div className="footer__item-icon">
              <div className="footer__icon">
                <SVGIcon name="whatsapp" size="md" color={theme.gray.gray40} />
              </div>
              <div>
                <FooterLink link="https://wa.me/6281119027360" text="0811 1902 7360" />
              </div>
            </div>
          </div>
        </div>
        <Separator color={theme.gray.gray60} noMargin />
        <div className="footer__wrapper">
          <div className="footer__bottom-content">
            <Typography
              variant="paragraph"
              level="2"
              tagName="span"
              color={theme.gray.gray40}
              className="footer__copyright"
            >
              {`©${new Date().getFullYear()}. All rights reserved.`}
            </Typography>
          </div>
          <div className="footer__bottom-content">
            <FooterLink link="https://campus.quipper.com/terms" text="Syarat dan Ketentuan" />
            <FooterLink link="https://campus.quipper.com/privacy" text="Kebijakan Privasi" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Footer
