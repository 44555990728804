import React, { useState, useContext, useEffect } from 'react'

import addEventGA from 'utils/addEventGA'
import addMinutes from 'date-fns/addMinutes'
import { withTheme } from 'emotion-theming'
import { css } from '@emotion/core'

import HomeActiveSVG from 'svgs/home-active.svg'
import HomeSVG from 'svgs/home.svg'
import MenuActiveSVG from 'svgs/directory-full.svg'
import MenuSVG from 'svgs/directory.svg'
import AssessmentHistorySVG from 'svgs/assessment-history.svg'
import HeartOutlineSVG from 'svgs/heart-outline.svg'
import UserActiveSVG from 'svgs/user-active.svg'
import UserSVG from 'svgs/user.svg'

import Navbar from 'components/common/organisms/Navbar'
import Footer from 'components/layouts/molecules/Footer'
import Container from 'components/layouts/atoms/Container'
import StickyBottomNavbar from 'components/common/molecules/StickyBottomNavbar'
import TermsConditionModal from 'components/common/organisms/TermsConditionModal'
import MenuItem from 'components/common/molecules/MenuItem'
import MobileFullscreenMenu from 'components/common/organisms/MobileFullcreenMenu'
import PopupSignup from 'components/common/organisms/PopupSignup'

import { MENU_ITEMS } from 'utils/constants/menuItems'
import { PROFILE_MENU_ITEMS } from 'utils/constants/profileMenuItems'
import { lockScroll, unlockScroll } from 'utils/bodyScrollLock'
import { ActionType as ActionTypeProfile, AsyncStatus, ProfileCtx } from 'components/utils/ProfileProvider'
import { PageCtx } from 'components/utils/PageProvider'
import { useRouter } from 'next/router'
import NavbarCustom from 'components/common/molecules/NavbarCustom'
import NavbarWrapper from 'components/common/atoms/NavbarWrapper'
import NotificationPopup from 'components/notifications/organisms/NotificationPopup'
import { NotificationCtx, ActionType, NotifStatus } from 'components/utils/NotificationProvider'
import FooterCompact from 'components/layouts/molecules/FooterCompact'
import { triggerEvent } from 'utils/dataLayer'

type CampusAppLayoutProps = {
  children: React.ReactNode
}

type TMenu = 'idle' | 'directory' | 'profile' | 'assessmentHistory'

type StickyMobileMenuProps = {
  setActiveMenu: (menuType: TMenu) => void
  activeMenu: TMenu
}

const mobileMenuStyle = css`
  display: block;
  z-index: 98;
  height: 56px;
  box-shadow: 0 -2px 12px 0 rgba(26, 52, 79, 0.1);
  background-color: #ffffff;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  .mobile-menu-items {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const mobileHiddenStyle = css`
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const StickyMobileMenu = ({ setActiveMenu, activeMenu }: StickyMobileMenuProps) => {
  const { state } = useContext(PageCtx)
  const { state: notifState } = useContext(NotificationCtx)
  const router = useRouter()
  const historyPath = '/aptitude_test/history'
  return (
    <div
      className="mobile-menu"
      css={[
        mobileMenuStyle,
        (state.hideMobileMenu ||
          (notifState.showNotificationPopup && notifState.notifStatus === NotifStatus.completed)) &&
          mobileHiddenStyle,
      ]}
    >
      <Container isMobileFull>
        <div className="mobile-menu-items">
          <MenuItem
            icon={router.pathname === '/' && activeMenu === 'idle' ? <HomeActiveSVG /> : <HomeSVG />}
            iconName={router.pathname === '/' && activeMenu === 'idle' ? 'home-active' : 'home'}
            label="Beranda"
            onClick={() => {
              if (router.pathname !== '/') {
                router.push('/')
              }
              setActiveMenu('idle')
              addEventGA('navigation', 'global', 'ga/global/button-navbar/home')
            }}
            data-testid="menu-home-mb"
            isActive={router.pathname === '/' && activeMenu === 'idle'}
          />
          <MenuItem
            icon={activeMenu === 'directory' ? <MenuActiveSVG /> : <MenuSVG />}
            iconName={activeMenu === 'directory' ? 'menu-active' : 'menu'}
            label="Direktori"
            onClick={() => {
              setActiveMenu('directory')
              addEventGA('navigation', 'global', 'ga/global/button-navbar/directory')
            }}
            data-testid="menu-direktori-mb"
            isActive={activeMenu === 'directory'}
          />
          <MenuItem
            icon={<HeartOutlineSVG />}
            iconName="heart-outline"
            label="Favorit"
            url="/likes/schools"
            data-testid="menu-favovit-mb"
            onClick={() => addEventGA('navigation', 'global', 'ga/global/button-navbar/favourite')}
          />
          <MenuItem
            icon={<AssessmentHistorySVG />}
            iconName="assessment-history"
            label="Riwayat Tes"
            url={router.pathname === historyPath ? '#' : historyPath}
            data-testid="menu-riwayat-mb"
            onClick={() => {
              setActiveMenu('assessmentHistory')
              addEventGA('navigation', 'global', 'ga/global/button-navbar/history')
            }}
            isActive={router.pathname === historyPath && (activeMenu === 'assessmentHistory' || activeMenu === 'idle')}
            checkTerms
          />
          <MenuItem
            icon={activeMenu === 'profile' ? <UserActiveSVG /> : <UserSVG />}
            iconName={activeMenu === 'profile' ? 'user-active' : 'user'}
            label="Profil"
            onClick={() => {
              setActiveMenu('profile')
              addEventGA('account', 'global', 'ga/global/button-navbar/profile')
            }}
            data-testid="menu-profil-mb"
            isActive={activeMenu === 'profile'}
          />
        </div>
      </Container>
    </div>
  )
}

const ProfileMenuWithCtx = () => {
  const { state: profileState } = useContext(ProfileCtx)

  const ProfileMenuItems =
    profileState.authStatus === 'authorized'
      ? [
          {
            url: '/profile',
            imageUrl: '/next-assets/images/avatar.png',
            name: profileState.profile.firstName,
            label: 'profile',
          },
          ...PROFILE_MENU_ITEMS,
        ]
      : [
          {
            url: '/signup',
            name: 'Daftar',
            label: 'signup',
          },
          {
            url: '/signin',
            name: 'Masuk',
            label: 'signin',
          },
        ]

  return <MobileFullscreenMenu items={ProfileMenuItems} />
}

const CampusAppLayout: React.FC<CampusAppLayoutProps> = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState<TMenu>('idle')
  const [showPopupSignup, setShowPopupSignup] = useState(false)
  const [popUpSignupTop, setPopupSignupTop] = useState(0)
  const { state } = useContext(PageCtx)
  const { state: profileState, dispatch: dispatchProfile } = useContext(ProfileCtx)
  const { state: notifState, dispatch } = useContext(NotificationCtx)
  const router = useRouter()

  const setActiveMenuToggle = (menuType: TMenu) => {
    setActiveMenu(menuType === activeMenu ? 'idle' : menuType)
  }

  const closePopupSignup = () => {
    setShowPopupSignup(false)
    triggerEvent('popup', { popup_action: 'close', page_location: router.pathname })
    unlockScroll()
  }

  const openPopupSignup = () => {
    setShowPopupSignup(true)
    triggerEvent('popup', { popup_action: 'show', page_location: router.pathname })
    window.localStorage.setItem('popUpSignupStatus', 'hasOpen')
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    setPopupSignupTop(window.scrollY)
    lockScroll()
  }

  const initPopupSignup = () => {
    window.localStorage.setItem('popUpSignupTime', addMinutes(new Date(), 2).getTime().toString())
    window.localStorage.setItem('popUpSignupStatus', 'enabled')
  }

  useEffect(() => {
    const status = window.localStorage.getItem('popUpSignupStatus')
    if (profileState.isFetching !== AsyncStatus.idle) {
      if (profileState.authStatus === 'authorized') {
        const isTermsPage = new RegExp(/(terms|privacy|error\/404|error\/500)$/).test(router.pathname)

        if (!(profileState.agreedToTerms && profileState.agreedToPolicy) && !isTermsPage) {
          dispatchProfile({ type: ActionTypeProfile.SHOW_TERMS_MODAL })
        } else {
          status !== 'disabled' && window.localStorage.setItem('popUpSignupStatus', 'disabled')
        }
      } else {
        if (!status || status === 'disabled') {
          initPopupSignup()
        }

        const timer = setInterval(() => {
          const currentStatus = window.localStorage.getItem('popUpSignupStatus')
          const currentTimeLimit = window.localStorage.getItem('popUpSignupTime') || ''
          if (currentStatus !== 'enabled') {
            clearInterval(timer)
          } else if (parseInt(currentTimeLimit) < new Date().getTime() && !document.hidden) {
            openPopupSignup()
          }
        }, 5000)
      }
    }
  }, [profileState.authStatus, profileState.isFetching])

  const showCustomNavbar = new RegExp(/^(directory|profile)$/).test(activeMenu)
  const navbar = () => {
    if (!state.hideNavbar) {
      if (showCustomNavbar) {
        return (
          <NavbarWrapper>
            <NavbarCustom title={activeMenu === 'directory' ? 'Direktori' : 'Profil'} showNavigation={false} />
          </NavbarWrapper>
        )
      }
      return <Navbar />
    }
    return null
  }
  return (
    <div
      className="campus-app-layout"
      css={css`
        margin-bottom: ${state.useStickyBottom ? '68px' : state.hideMobileMenu ? '0' : '56px'};
        @media screen and (max-width: 767px) {
          .footer {
            margin-bottom: ${state.hideMobileMenu ? '0' : '56px'};
          }
        }
        @media screen and (min-width: 768px) {
          margin-bottom: ${state.useStickyBottom ? '68px' : '0'};
        }
      `}
    >
      {showPopupSignup && <PopupSignup close={() => closePopupSignup()} top={popUpSignupTop} />}
      {navbar()}
      {activeMenu === 'directory' && <MobileFullscreenMenu items={MENU_ITEMS} />}
      {activeMenu === 'profile' && <ProfileMenuWithCtx />}
      <div
        className="campus-app-content"
        css={css`
          padding-top: ${state.hideNavbar ? '0' : '68px'};
          @media screen and (min-width: 768px) {
            padding-top: ${state.hideNavbar ? '0' : '78px'};
          }
        `}
      >
        {children}
      </div>
      <TermsConditionModal />
      {state.useCompactFooter ? <FooterCompact /> : <Footer />}
      <StickyMobileMenu setActiveMenu={setActiveMenuToggle} activeMenu={activeMenu} />
      {state.useStickyBottom && (
        <StickyBottomNavbar
          title={state.stickyContent?.title}
          buttonText={state.stickyContent?.buttonText}
          link={state.stickyContent?.link}
          firstSection={state.stickyContent?.firstSection}
        />
      )}
      {notifState.showNotificationPopup && (
        <NotificationPopup
          close={() =>
            dispatch({
              type: ActionType.TOGGLE_NOTIFICATION_POPUP,
              payload: { showNotificationPopup: false },
            })
          }
        />
      )}
    </div>
  )
}

export default withTheme(CampusAppLayout)
