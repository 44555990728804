const getLinkAttr = (href: string) => {
  const isQuipperLink = href.indexOf('quipper.com') >= 0
  const isQuipperCampus = href.indexOf('campus.quipper.com') >= 0

  if (href === '') {
    return {}
  }
  if (isQuipperLink) {
    if (!isQuipperCampus) {
      return { target: '_blank', rel: 'noopener' }
    }
    return {}
  }
  return {
    target: '_blank',
    rel: 'nofollow noopener',
  }
}

export default getLinkAttr
