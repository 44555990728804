import React from 'react'

import { css } from '@emotion/core'
import Relazsizes from 'relazsizes'

import NewTypography from 'components/common/atoms/NewTypography'
import flexCenter from 'utils/styles/flexCenter'

const emptyNotificationStyle = css`
  .empty-notification {
    &__wrapper {
      padding: 100px 24px 0;
      text-align: center;
    }
    &__title {
      margin: 16px 0 8px;
    }
    &__img {
      width: 200px;
      height: 125px;
      margin: auto;
      ${flexCenter}
      img {
        height: 90px;
      }
    }
    @media screen and (min-width: 768px) {
      &__wrapper {
        padding: 44px 40px;
      }
      &__img {
        height: 100px;
      }
    }
  }
`

const EmptyNotification = () => {
  return (
    <div className="empty-notification" css={emptyNotificationStyle}>
      <div className="empty-notification__wrapper">
        <div className="empty-notification__img">
          <Relazsizes
            src="/next-assets/images/skeleton.svg"
            dataSrc="/next-assets/images/empty_notification.svg"
            alt="Empty Notification"
          />
        </div>
        <NewTypography variant="heading" level="2" tagName="h6" className="empty-notification__title">
          Tidak ada notifikasi baru
        </NewTypography>
        <NewTypography variant="paragraph" level="3" tagName="div">
          Semua pemberitahuan terkait Quipper Campus dapat kamu temukan di sini.
        </NewTypography>
      </div>
    </div>
  )
}

export default EmptyNotification
