import React, { useState, useEffect, useRef } from 'react'

import { css } from '@emotion/core'

import Button from 'components/common/atoms/Button'
import Typography from 'components/common/atoms/Typography'
import Container from 'components/layouts/atoms/Container'

type StickyBottomNavbarProps = {
  title?: string
  buttonText?: string
  link?: string
  firstSection?: string
}

const BottomNavbarStyle = () => css`
  box-shadow: 0 -2px 12px 0 rgba(26, 52, 79, 0.1);
  background-color: #fff;
  .sticky-bottom-navbar {
    &__wrapper {
      padding: 12px 0;
      display: flex;
      align-items: center;
    }
    &__btn {
      padding: 12px 24px;
      display: block;
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      &__message {
        width: 138px;
        margin-right: 16px;
      }
      &__title {
        font-size: 10px;
        line-height: 1.5;
        font-weight: normal;
      }
    }
    @media screen and (min-width: 768px) {
      &__message {
        flex-grow: 1;
      }
    }
  }
`

const StickyBottomNavbarStyle = css`
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: 100%;
`

const StickyBottomNavbar = (props: StickyBottomNavbarProps) => {
  const { title, buttonText, link, firstSection } = props

  const prevScrollY = useRef(0)
  const [sticky, setSticky] = useState(false)
  const [goingUp, setGoingUp] = useState(false)
  const [sectionHeight, setSectionHeight] = useState(0)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false)
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true)
    }
    if (currentScrollY >= sectionHeight) {
      !sticky && setSticky(true)
    } else {
      sticky && setSticky(false)
    }

    prevScrollY.current = currentScrollY
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const breadcrumbHeight = document.querySelector<HTMLElement>('.breadcrumb')?.offsetHeight || 0
      const firstSectionHeight = document.querySelector<HTMLElement>(firstSection || '')?.offsetHeight || 0
      const totalHeight = breadcrumbHeight + firstSectionHeight
      setSectionHeight(totalHeight)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [goingUp])

  return (
    <div className="sticky-bottom-navbar" css={[sticky && StickyBottomNavbarStyle, BottomNavbarStyle()]}>
      <Container>
        <div className="sticky-bottom-navbar__wrapper">
          <div className="sticky-bottom-navbar__message">
            <Typography variant="body" level="1" tagName="p" fontWeight="bold" className="sticky-bottom-navbar__title">
              {`${title}`}
            </Typography>
          </div>
          <div className="sticky-bottom-navbar__action">
            <Button variant="tertiary" tagName="a" href={link} className="sticky-bottom-navbar__btn">
              <Typography
                variant="heading"
                level="2"
                tagName="span"
                fontWeight="bold"
                color="white"
                className="sticky-bottom-navbar__btn-text"
              >
                {`${buttonText}`}
              </Typography>
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default StickyBottomNavbar
