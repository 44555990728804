import React, { FC, ReactNode, AllHTMLAttributes } from 'react'
import Link from 'next/link'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

interface ButtonProps<T> extends AllHTMLAttributes<T> {
  tagName?: keyof JSX.IntrinsicElements
  variant?: 'default' | 'primary' | 'secondary' | 'tertiary'
  type?: 'button' | 'submit' | 'reset'
  kind?: 'small' | 'medium' | 'large' | 'select' | 'floating'
  isDisabled?: boolean
  isFullWidth?: boolean
  className?: string
  children: ReactNode
  href?: string
  openNewTab?: boolean
  isActive?: boolean
}

const buttonBaseStyle = (theme: ThemeType, isFullWidth: boolean) => css`
  display: inline-block;
  width: ${isFullWidth ? '100%' : 'inherit'};
  border-radius: 4px;
  margin: 1rem 1rem 1rem 0;
  line-height: 1.5;
  font-weight: bolder;
  color: ${theme.gray.gray70};
  border: none;
  background-color: #fff;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`

const buttonKindStyle = (kind: ButtonProps<{}>['kind']) => {
  switch (kind) {
    case 'small':
      return css`
        font-size: 12px;
        padding: 0.5rem;
        min-width: 60px;
      `
    case 'medium':
      return css`
        font-size: 14px;
        padding: 0.5rem 1rem;
        min-width: 80px;
      `
    case 'select':
      return css`
        font-size: 14px;
        padding: 0.5rem 1rem;
        border-radius: 20px;
      `
    case 'floating':
      return css`
        font-size: 14px;
        padding: 0.5rem 1rem;
        border-radius: 24px;
      `
    default:
      return css`
        font-size: 16px;
        padding: 1rem 1.5rem;
        min-width: 100px;
      `
  }
}

const buttonVariantStyle = (
  theme: ThemeType,
  variant: ButtonProps<{}>['variant'],
  kind: ButtonProps<{}>['kind'],
  isActive: ButtonProps<{}>['isActive']
) => {
  const { white } = theme.neutral
  const { gray20, gray30, gray40, gray50, gray70 } = theme.gray
  const { darkblue60, darkblue40, darkblue30 } = theme.secondary
  const { aqua10, aqua20, aqua30, aqua40, aqua50 } = theme.campus
  if (kind === 'floating') {
    const boxShadow = css`
      box-shadow: ${isActive ? '0 1px 4px 0 rgba(26, 52, 79, 0.4)' : '0 2px 12px 0 rgba(26, 52, 79, 0.1)'};
      &:hover {
        box-shadow: 0 2px 8px 0 rgba(26, 52, 79, 0.4);
      }
      &:active {
        box-shadow: 0 1px 4px 0 rgba(26, 52, 79, 0.4);
      }
      &:disabled {
        box-shadow: none;
        border: none;
      }
    `
    if (variant === 'secondary') {
      return css`
        ${boxShadow}
        border: solid 1px ${gray40};
        background-color: ${isActive ? gray20 : white};
        color: ${gray70};
        &:active {
          background-color: ${gray20};
        }
      `
    }
    return css`
      ${boxShadow}
      background-color: ${isActive ? aqua40 : aqua30};
      color: ${white};
      &:hover {
        background-color: ${aqua20};
      }
      &:active {
        background-color: ${aqua40};
      }
    `
  }
  if (kind === 'select') {
    return css`
      border: solid 1px ${isActive ? aqua30 : gray30};
      background-color: ${isActive ? aqua10 : white};
      color: ${isActive ? aqua30 : gray50};
      &:hover {
        color: ${isActive ? aqua30 : gray40};
        border-color: ${isActive ? aqua30 : gray20};
        background-color: ${isActive ? aqua10 : white};
      }
      &:active {
        background-color: ${aqua10};
        border-color: ${aqua30};
        color: ${aqua30};
      }
      &:disabled {
        border: none;
      }
    `
  }
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${isActive ? aqua50 : aqua30};
        color: ${white};
        &:hover {
          background-color: ${aqua20};
        }
        &:active {
          background-color: ${aqua50};
        }
      `
    case 'secondary':
      return css`
        border: solid 1px ${gray40};
        background-color: ${isActive ? gray20 : white};
        &:hover {
          color: ${gray50};
        }
        &:active {
          background-color: ${gray20};
          color: ${gray70};
        }
      `
    case 'tertiary':
      return css`
        background-color: ${isActive ? darkblue60 : darkblue40};
        color: ${white};
        &:hover {
          background-color: ${darkblue30};
          box-shadow: none;
        }
        &:active {
          background-color: ${darkblue60};
        }
      `
    default:
      return css`
        color: ${aqua30};
      `
  }
}

const buttonDisabledStyle = (theme: ThemeType) => css`
  background-color: ${theme.gray.gray30};
  color: ${theme.neutral.white};
  cursor: not-allowed;
  border: none;
  &:hover {
    background-color: ${theme.gray.gray30};
    color: ${theme.neutral.white};
  }
`

const Button: FC<ButtonProps<{}>> = ({
  tagName = 'button',
  variant = 'default',
  isDisabled = false,
  isFullWidth = false,
  children,
  kind = 'large',
  type,
  href,
  openNewTab = true,
  isActive = false,
  ...props
}) => {
  const theme: ThemeType = useTheme()
  const Wrapper = tagName as 'button'
  const styles = [
    buttonBaseStyle(theme, isFullWidth),
    buttonVariantStyle(theme, variant, kind, isActive),
    buttonKindStyle(kind),
    isDisabled && buttonDisabledStyle(theme),
  ]
  // for to outside campus-next
  if (tagName === 'a') {
    return (
      <a
        type={type}
        css={styles}
        href={href || '#'}
        target={openNewTab ? '_blank' : '_self'}
        rel="noopener noreferrer"
        {...props}
        role="a"
      >
        {children}
      </a>
    )
  }

  // for link to inside campus-next client router
  if (tagName === 'link') {
    return (
      <Link passHref href={href || '/'} role="link" css={styles} {...props}>
        {children}
      </Link>
    )
  }

  return (
    <Wrapper type={type} disabled={isDisabled} role="button" css={styles} {...props}>
      {children}
    </Wrapper>
  )
}

export default Button
