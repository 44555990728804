import React, { FC, ReactChild } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

interface NavbarWrapperProps {
  height?: number
  children: ReactChild
}

const navbarWrapperStyle = (theme: ThemeType, height?: number) => css`
  width: 100%;
  height: ${height || '64'}px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: ${theme.neutral.white};

  border-top: solid 4px ${theme.secondary.darkblue60};
  border-image: linear-gradient(to right, #00beff, #0fc3c4) 1 0 0 !important;
  box-shadow: 0 2px 12px 0 rgba(26, 52, 79, 0.1);
  @media screen and (min-width: 768px) {
    height: 78px;
  }
`

const NavbarWrapper: FC<NavbarWrapperProps> = ({ height, children }) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="navbar-wrapper" css={navbarWrapperStyle(theme, height)} data-testid="navbar-wrapper">
      {children}
    </div>
  )
}

export default NavbarWrapper
