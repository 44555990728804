import React, { ReactNode } from 'react'

import { css } from '@emotion/core'
import NavbarCustom from 'components/common/molecules/NavbarCustom'
import NavbarWrapper from 'components/common/atoms/NavbarWrapper'

type PopupWrapperProps = {
  close: () => void
  closeIcon?: 'cancel' | 'back'
  title?: string
  children?: ReactNode
}

const popupWrapperStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  right: 0;
  @media screen and (min-width: 768px) {
    display: none;
  }
`

const PopupWrapper = ({ children, close, title, closeIcon = 'cancel' }: PopupWrapperProps) => {
  return (
    <div className="popup-wrapper" css={popupWrapperStyle}>
      <NavbarWrapper>
        <NavbarCustom title={title} onClick={close} icon={closeIcon} />
      </NavbarWrapper>
      {children}
    </div>
  )
}

export default PopupWrapper
