import React from 'react'

import { css } from '@emotion/core'

type SeparatorProps = {
  dataTestId?: string
  color?: string
  noMargin?: boolean
}

const Separator = ({ color, dataTestId, noMargin = false }: SeparatorProps) => (
  <hr
    data-testid={dataTestId || ''}
    className="separator"
    css={css`
      border: 0;
      border-top: ${`1px solid ${color || 'rgba(0, 0, 0, 0.1)'}`};
      margin: ${noMargin ? 0 : '1.5rem 0'};
    `}
  />
)

export default Separator
