import React, { FC, useContext } from 'react'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import { getSearchUrl } from 'components/search/searchUtils'
import { SearchCtx } from 'components/search/SearchStateCtx'

type SearchResultItemProps = {
  result: {
    id: string
    text: string
    type: string
  }
}

const resultItemStyle = (theme: ThemeType) => css`
  display: flex;
  align-items: center;
  padding: 9.5px 16px;
  span {
    font-size: 14px;
    color: ${theme.gray.gray70};
  }
  &:hover,
  &.active {
    background-color: ${theme.gray.gray20};
  }
  @media screen and (min-width: 768px) {
    padding: 12px 24px;

    span {
      font-size: 16px;
    }
  }
`

const SearchResultItem: FC<SearchResultItemProps> = ({ result }) => {
  const theme: ThemeType = useTheme()
  const { state } = useContext(SearchCtx)

  return (
    <a
      href={getSearchUrl(result.id)}
      className={`result ${state.selectedId === result.id ? 'active' : ''}`}
      css={resultItemStyle(theme)}
    >
      <span dangerouslySetInnerHTML={{ __html: result.text }} />
    </a>
  )
}

export default SearchResultItem
