import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import fetch from 'isomorphic-unfetch'
import { NextPageContext } from 'next'
import nookies from 'nookies'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApolloClient(initialState: any, ctx: NextPageContext | undefined) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  // const ssrMode = Boolean(ctx)

  // let's keep the ctx code above
  // since we are still get best practice to handle this
  const ssrMode = !process.browser
  const { learn_auth_token: token } = nookies.get(ctx)

  const uri = (() => {
    if (process.env.NODE_ENV !== 'production') {
      return ssrMode ? 'http://campus-server:3000/api/graphql' : `${process.env.CAMPUS_SERVER_HOST}/api/graphql`
    } else {
      return ssrMode ? 'http://campus-server/api/graphql' : `https://${process.env.SITE_URL}/api/graphql`
    }
  })()

  return new ApolloClient({
    ssrMode,
    link: new HttpLink({
      uri, // Server URL (must be absolute)
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
      headers: {
        Authorization: token ? `Bearer ${decodeURIComponent(token)}` : '',
      },
      fetch,
    }),
    cache: new InMemoryCache().restore(initialState),
  })
}
