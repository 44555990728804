import React, { useContext, useState } from 'react'
import NewTypography from 'components/common/atoms/NewTypography'
import FormGroup from 'components/form/molecules/FormGroup'
import TextInput from 'components/form/atoms/TextInput'
import SubmitButton from 'components/form/molecules/SubmitButton'
import { Form, Formik } from 'formik'
import { SearchCtx } from 'components/search/SearchStateCtx'
import SubmitMessage from '../SubmitMessage'
import api from 'utils/api'
import Relazsizes from 'relazsizes'
import { css } from '@emotion/core'
import SelectButtonGroup from 'components/form/molecules/SelectButtonGroup'
import { parseCookies } from 'nookies'
import { PageCtx, SearchCategory } from 'components/utils/PageProvider'

const typeList = ['Kampus', 'Jurusan', 'Lokasi', 'Karier', 'Uji Potensi', 'Beasiswa', 'Lainnya']

interface FormValues {
  description?: string
  type?: string
}

const autoCompleteNoResultsStyle = css`
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
  .autocomplete-no-results {
    &__container {
      text-align: center;
    }
    &__img {
      width: 140px;
      height: 140px;
      margin: 8px auto 16px;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: block;
    }
    &__text {
      margin: 8px 0;
    }
    &__form {
      margin-top: 24px;
      .text-input {
        font-size: 14px;
        line-height: 16px;
      }
      .select-button-group {
        margin-bottom: 0;
      }
    }
    &__btn {
      font-size: 14px;
      line-height: 16px;
      padding: 12px 24px;
      margin: 40px 0 0 0;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 24px;
    height: inherit;
    .autocomplete-no-results {
      &__text {
        margin: 16px 0 24px;
      }
      &__btn {
        width: inherit;
        display: block;
        margin-left: auto;
      }
    }
  }
`

const AutoCompleteNoResults = () => {
  const [showSubmitMsg, setShowSubmitMsg] = useState(false)
  const { state } = useContext(SearchCtx)
  const {
    state: { searchCategory },
  } = useContext(PageCtx)
  const sendFeedback = async ({ description, type }: FormValues, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true)
    const data = {
      keyword: state.keyword,
      description: description,
      type: searchCategory === SearchCategory.kampuspedia ? 'Kampuspedia' : type,
    }
    const { [process.env.AUTH_TOKEN_COOKIE_NAME || 'learn_auth_token']: token } = parseCookies()
    const headers = {
      authorization: `BEARER ${decodeURIComponent(token)}`,
    }
    await api('campus').post('/search/not_found', { searchParams: data, headers: token ? headers : {} })
    setSubmitting(false)
    setShowSubmitMsg(true)
  }

  const typeOpts = () => {
    const data: { label: string; value: string }[] = []
    const kampuspedia = { label: 'Kampuspedia', value: 'Kampuspedia' }
    typeList.forEach((val) => data.push({ label: val, value: val }))
    searchCategory === SearchCategory.all && data.splice(6, 0, kampuspedia)
    return data
  }
  return (
    <div className="autocomplete-no-results" css={autoCompleteNoResultsStyle}>
      {showSubmitMsg ? (
        <SubmitMessage />
      ) : (
        <div className="autocomplete-no-results__container">
          <Relazsizes
            src="/next-assets/images/skeleton.svg"
            dataSrc="/next-assets/images/search/not_found.png"
            alt="Search Not Found"
            className="autocomplete-no-results__img"
          />
          <NewTypography tagName="h4" variant="heading" level="1">
            Oops, pencarian untuk “{state.keyword}” tidak ditemukan!
          </NewTypography>

          <NewTypography
            tagName="p"
            variant="paragraph"
            level="2"
            levelPC="1"
            className="autocomplete-no-results__text"
          >
            Ayo bantu Quipper Campus untuk lebih hebat lagi dengan mengisi form berikut:
          </NewTypography>

          <div className="autocomplete-no-results__form">
            <Formik
              initialValues={{
                description: '',
                type: 'Kampus',
              }}
              onSubmit={(values, { setSubmitting }) => {
                sendFeedback(values, setSubmitting)
              }}
            >
              <Form>
                <FormGroup
                  id="description"
                  label="Deskripsikan yang ingin kamu cari"
                  name="description"
                  placeholder="Tulis deskripsi kamu disini…"
                  renderInputField={(props) => <TextInput {...props} />}
                />
                {searchCategory !== SearchCategory.kampuspedia && (
                  <SelectButtonGroup id="type" label="Kategori" name="type" options={typeOpts()} />
                )}
                <SubmitButton className="autocomplete-no-results__btn">Kirim</SubmitButton>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </div>
  )
}

export default AutoCompleteNoResults
