import React, { FC } from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

import NewTypography from 'components/common/atoms/NewTypography'
import Separator from 'components/common/atoms/Separator'
import getLinkAttr from 'components/utils/getLinkAttr'

type NotificationCardProps = {
  title: string
  description: string
  url: string
  image?: string
  startDate: string
  isActive: boolean
  onClick?: () => void
}

const notificationCardStyle = (theme: ThemeType, isActive: boolean) => css`
  background-color: ${isActive ? theme.campus.aqua10 : theme.neutral.white};
  &:hover {
    background-color: ${theme.gray.gray10};
    hr {
      border-color: ${theme.gray.gray20};
    }
  }
  &:last-of-type hr {
    display: none;
  }
  .notification-card {
    &__flex {
      display: flex;
    }
    &__img {
      background-color: ${theme.neutral.white};
      border-radius: 4px;
      border: solid 1px ${theme.gray.gray20};
      padding: 8px;
      img {
        width: 24px;
        height: 24px;
        display: block;
        margin: 0 auto;
      }
    }
    &__container {
      padding: 16px;
    }
    &__wrapper {
      width: 100%;
    }
    &__container-msg {
      padding: 16px 16px 16px 0;
    }
    &__msg {
      margin-bottom: 8px;
    }
    &__date {
      text-align: right;
    }
  }
`

const NotificationCard: FC<NotificationCardProps> = ({
  title,
  description,
  url,
  image,
  startDate,
  isActive,
  onClick,
}) => {
  const theme: ThemeType = useTheme()
  return (
    <div className="notification-card" css={notificationCardStyle(theme, isActive)}>
      <a href={url} onClick={onClick} {...getLinkAttr(url)}>
        <div className="notification-card__flex">
          <div className="notification-card__container">
            <div className="notification-card__img">
              <img src={image || '/next-assets/images/placeholder_notification.svg'} alt="Notification" />
            </div>
          </div>
          <div className="notification-card__wrapper">
            <div className="notification-card__container-msg">
              <NewTypography
                tagName="h3"
                variant="paragraph"
                level="2"
                fontWeight="600"
                color={theme.gray.gray70}
                className="notification-card__msg"
              >
                {title}
              </NewTypography>
              <NewTypography
                tagName="p"
                variant="paragraph"
                level="3"
                color={theme.gray.gray70}
                className="notification-card__msg"
              >
                {description}
              </NewTypography>
              <NewTypography
                tagName="p"
                variant="paragraph"
                level="4"
                color={theme.gray.gray50}
                className="notification-card__date"
              >
                {startDate}
              </NewTypography>
            </div>
            <Separator color={isActive ? theme.gray.gray30 : theme.gray.gray20} noMargin />
          </div>
        </div>
      </a>
    </div>
  )
}

export default NotificationCard
