import React, { ReactNode } from 'react'
import { css } from '@emotion/core'

type StickyNavbar = {
  sticky?: boolean
  children?: ReactNode
}

const stickyNavbarStyle = css`
  position: fixed;
  height: fit-content;
  bottom: 0;
  top: 78px;
  z-index: 98;
  width: 100%;
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const hideStickyNavbar = css`
  display: none;
`

const StickyNavbar = (props: StickyNavbar) => {
  const { sticky, children } = props

  return (
    <div className="sticky-navbar" css={sticky ? stickyNavbarStyle : hideStickyNavbar}>
      {children}
    </div>
  )
}

export default StickyNavbar
