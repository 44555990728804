import React, { useEffect } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { Global, css } from '@emotion/core'
import ReactGA from 'react-ga'

import CampusThemeProvider from 'components/utils/ThemeProvider'
import AuthPageLayout from 'components/templates/AuthPageLayout'
import CampusLayout from 'components/templates/CampusLayout'

import * as Sentry from '@sentry/node'
import { ProfileProvider } from 'components/utils/ProfileProvider'
import { PageProvider } from 'components/utils/PageProvider'

import NotificationWithProvider from 'components/utils/NotificationProvider'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
})

ReactGA.initialize('UA-112768068-1', { titleCase: false, testMode: process.env.NODE_ENV !== 'production' })

function CampusApp({ Component, pageProps, router }: AppProps) {
  const isAuthPath = new RegExp(/(signin|signup|signup\/application)$/).test(router.pathname)
  const LayoutWrapper = isAuthPath ? AuthPageLayout : CampusLayout

  useEffect(() => {
    ReactGA.pageview(router.asPath)
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0,width=device-width,height=device-height" key="viewport" />
        <meta name="theme-color" content="#04C2C9" key="themeColor" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#04C2C9" />
        <meta name="google-site-verification" content="6muPCmEkncRoqZi9pSDNf2nC6zuUEKq5w4uNDsLjIqc" />
      </Head>
      <div className="campus-app">
        <CampusThemeProvider>
          <Global
            styles={css`
              *,
              html,
              body {
                margin: 0;
                padding: 0;
                font-size: 16px;
                font-family: 'Open Sans', sans-serif;
                box-sizing: border-box;
                color: #1a344f;
              }
              a {
                font-size: inherit;
                color: #00449c;
                text-decoration: none;
              }
              button {
                a {
                  color: inherit;
                }
              }
              [data-minimized-position='bottom_right'] {
                @media screen and (max-width: 767px) {
                  bottom: 32px !important;
                }
              }
            `}
          />
          <ProfileProvider>
            <PageProvider>
              <NotificationWithProvider>
                <LayoutWrapper>
                  <Component {...pageProps} />
                </LayoutWrapper>
              </NotificationWithProvider>
            </PageProvider>
          </ProfileProvider>
        </CampusThemeProvider>
      </div>
    </>
  )
}

export default CampusApp
