import React, { FC } from 'react'

import PopupWrapper from 'components/common/molecules/PopupWrapper'
import PopupContentWrapper from 'components/common/atoms/PopupContentWrapper'
import NotificationContent from 'components/notifications/molecules/NotificationContent'

type NotificationPopupProps = {
  close: (e?: never) => void
}

const NotificationPopup: FC<NotificationPopupProps> = ({ close }) => {
  return (
    <PopupWrapper title="Notifikasi" close={close} closeIcon="back">
      <PopupContentWrapper>
        <div className="notification-popup">
          <NotificationContent />
        </div>
      </PopupContentWrapper>
    </PopupWrapper>
  )
}

export default NotificationPopup
