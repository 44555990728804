import React, { FC, ReactNode, useContext } from 'react'

import { useTheme } from 'emotion-theming'
import { css } from '@emotion/core'

import BackSVG from 'svgs/back.svg'
import CancelSVG from 'svgs/cancel.svg'
import Typography from 'components/common/atoms/Typography'
import flexCenter from 'utils/styles/flexCenter'
import Search from 'components/search'
import NotificationMenu from '../../../notifications/organisms/NotificationMenu'
import { ActionType, NotificationCtx } from 'components/utils/NotificationProvider'

interface NavbarCustomProps {
  onClick?: () => void
  icon?: 'cancel' | 'back'
  title?: string
  buttonContent?: ReactNode
  showNavigation?: boolean
  showMobileNotification?: boolean
}

const navbarCustomStyle = (theme: ThemeType) => css`
  height: 100%;
  padding: 0 16px;

  display: flex;
  align-items: center;
  .navbar-custom {
    &__title {
      flex-grow: 1;
    }
    &__navigation {
      margin-right: 16px;
      ${flexCenter}
      svg,
      svg path {
        fill: ${theme.gray.gray70};
      }
    }
  }
  @media screen and (min-width: 768px) {
    display: none !important;
  }
`

const NavbarCustom: FC<NavbarCustomProps> = ({
  icon,
  title,
  buttonContent,
  onClick,
  showNavigation = true,
  showMobileNotification = false,
}) => {
  const theme: ThemeType = useTheme()
  const { state: notifState, dispatch } = useContext(NotificationCtx)
  const onShowNotification = () => {
    dispatch({
      type: ActionType.TOGGLE_NOTIFICATION_POPUP,
      payload: { showNotificationPopup: true },
    })
  }

  return (
    <div className="navbar-custom" css={navbarCustomStyle(theme)}>
      {onClick && showNavigation && (
        <div onClick={onClick} onKeyDown={onClick} className="navbar-custom__navigation" data-testid="navbar-custom-back-btn">
          {icon === 'cancel' ? <CancelSVG /> : <BackSVG />}
        </div>
      )}
      {title ? (
        <Typography variant="heading" level="1" tagName="h2" className="navbar-custom__title">
          {title}
        </Typography>
      ) : (
        <Search />
      )}
      {notifState.showMobileNotification && showMobileNotification && (
        <div
          onClick={onShowNotification}
          onKeyDown={onShowNotification}
        >
          <NotificationMenu />
        </div>
      )}
      {buttonContent}
    </div>
  )
}

export default NavbarCustom
