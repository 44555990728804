import React, { FC } from 'react'

import { ThemeProvider } from 'emotion-theming'

export const theme: ThemeType = {
  campus: {
    aqua50: '#0090A3',
    aqua40: '#00A4AF',
    aqua30: '#00C2C9',
    aqua20: '#7ADEE3',
    aqua10: '#CEF2F6',
  },
  primary: {
    blue60: '#0081CC',
    blue50: '#009FEB',
    blue40: '#00BEFF',
    blue30: '#7DDCFF',
    blue20: '#CBF2FE',
    blue10: '#EBFAFF',
  },
  secondary: {
    darkblue60: '#00357B',
    darkblue50: '#00449C',
    darkblue40: '#0056B3',
    darkblue30: '#3176C2',
    darkblue20: '#5F95D0',
  },
  gray: {
    gray70: '#0C2846',
    gray60: '#1E3F6B',
    gray50: '#6B8FBC',
    gray40: '#8FB0D4',
    gray30: '#BED2E8',
    gray20: '#E3ECF5',
    gray10: '#F6F7FA',
  },
  success: {
    green60: '#008878',
    green50: '#00AE97',
    green40: '#00CBAE',
    green30: '#75E2C9',
    green20: '#BCEFE2',
    green10: '#E5F9F6',
  },
  error: {
    red60: '#C42151',
    red50: '#E3385C',
    red40: '#F9587A',
    red30: '#FBA3B1',
    red20: '#FCDDE2',
    red10: '#FEF2F7',
  },
  warning: {
    orange60: '#CC4004',
    orange50: '#E7580C',
    orange40: '#FC7D20',
    orange30: '#FFB580',
    orange20: '#FADFC9',
    orange10: '#FCEFE4',
  },
  additional: {
    yellow60: '#A69F03',
    yellow50: '#C3C001',
    yellow40: '#DED807',
    yellow30: '#ECEB73',
    yellow20: '#F5F3AE',
    yellow10: '#F8F7E0',
  },
  info: {
    purple60: '#443BA8',
    purple50: '#544DC9',
    purple40: '#766DDD',
    purple30: '#B2AEEB',
    purple20: '#E0DCF9',
    purple10: '#F0EFFC',
  },
  neutral: {
    black: '#000000',
    white: '#FFFFFF',
  },
}

const CampusThemeProvider: FC = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>

export default CampusThemeProvider
