import React from 'react'
import { css } from '@emotion/core'

import addEventGA from 'utils/addEventGA'
import Button from 'components/common/atoms/Button'

const unauthorizedMenuStyle = css`
  display: none;
  margin-left: 40px;
  a {
    margin: 16px 0;
    height: 100%;
    font-weight: bold;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      margin-right: 8px;
    }

    a {
      font-size: 14px;
    }
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
`

const UnauthorizedMenu = () => {
  return (
    <div className="navbar-unauthorized-menu" css={unauthorizedMenuStyle}>
      <Button
        kind="medium"
        tagName="link"
        href="/signup"
        variant="secondary"
        onClick={() => addEventGA('account', 'global', 'ga/global/button-navbar/signup')}
      >
        Daftar
      </Button>
      <Button
        kind="medium"
        tagName="link"
        href="/signin"
        variant="primary"
        onClick={() => addEventGA('account', 'global', 'ga/global/button-navbar/signin')}
      >
        Masuk
      </Button>
    </div>
  )
}

export default UnauthorizedMenu
