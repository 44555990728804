import React, { FC } from 'react'
import { css } from '@emotion/core'

import { withTheme } from 'emotion-theming'

type InfoMessageProps = {
  text: string
  color?: string
  theme: ThemeType
}

const infoMessageStyle = (color: string | undefined, theme: ThemeType) => css`
  font-size: 12px;
  color: ${color || theme.error.red60};
  padding-top: 8px;
`

const InfoMessage: FC<InfoMessageProps> = ({ text, color, theme }) => (
  <div className="message" css={infoMessageStyle(color, theme)}>
    {text}
  </div>
)

export default withTheme(InfoMessage)
