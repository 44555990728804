import React, { FC } from 'react'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'

type TypographyProps = {
  tagName?: keyof JSX.IntrinsicElements
  variant: 'title' | 'heading' | 'body' | 'paragraph'
  level: '1' | '2' | '3' | '4' | '5'
  fontWeight?: 'bold' | 'bolder' | 'lighter' | 'normal' | '400' | '600' | '700'
  fontStyle?: 'normal' | 'italic' | 'oblique' | 'inherit' | 'unset'
  color?: string
  className?: string
}

const typographyBaseStyle = css`
  line-height: 1.5;
  font-weight: normal;
  font-style: normal;
  em {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`

const titleLevel = (level: TypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 28px;
        line-height: 30px;
        @media screen and (min-width: 768px) {
          font-size: 42px;
          line-height: 44px;
        }
      `
    case '2':
      return css`
        font-size: 24px;
        line-height: 26px;
        @media screen and (min-width: 768px) {
          font-size: 28px;
          line-height: 30px;
        }
      `
    case '3':
      return css`
        font-size: 16px;
        line-height: 24px;

        @media screen and (min-width: 768px) {
          font-size: 24px;
          line-height: 26px;
        }
      `
    default:
      return css``
  }
}

const headingLevel = (level: TypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 16px;
        line-height: 18px;
      `
    case '2':
      return css`
        font-size: 14px;
        line-height: 16px;
      `
    case '3':
      return css`
        font-size: 12px;
        line-height: 14px;
      `
    case '4':
      return css`
        font-size: 10px;
        line-height: 12px;
      `
    default:
      return css``
  }
}

const bodyLevel = (level: TypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 14px;
        line-height: 16px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 18px;
        }
      `
    case '2':
      return css`
        font-size: 12px;
        line-height: 14px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 16px;
        }
      `
    case '3':
      return css`
        font-size: 10px;
        line-height: 12px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
          line-height: 14px;
        }
      `
    case '4':
      return css`
        font-size: 10px;
        line-height: 12px;
      `
    default:
      return css``
  }
}

const paragraphLevel = (level: TypographyProps['level']) => {
  switch (level) {
    case '1':
      return css`
        font-size: 14px;
        line-height: 21px;
        @media screen and (min-width: 768px) {
          font-size: 16px;
          line-height: 24px;
        }
      `
    case '2':
      return css`
        font-size: 12px;
        line-height: 18px;
        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 21px;
        }
      `
    default:
      return css``
  }
}

const typographyVariantStyle = (variant: TypographyProps['variant'], level: TypographyProps['level']) => {
  const weightBold = css`
    font-weight: bold !important;
  `
  const weightNormal = css`
    font-weight: normal;
  `
  switch (variant) {
    case 'title':
      return css`
        margin-bottom: 16px;
        ${weightBold}
        ${titleLevel(level)}
      `

    case 'heading':
      return css`
        ${weightBold}
        ${headingLevel(level)}
      `

    case 'body':
      return css`
        ${weightNormal}
        ${bodyLevel(level)}
      `

    case 'paragraph':
      return css`
        ${weightNormal}
        ${paragraphLevel(level)}
        * {
          ${weightNormal}
          ${paragraphLevel(level)}
        }
        p:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      `

    default:
      return css``
  }
}

const Typography: FC<TypographyProps> = ({
  tagName = 'span',
  variant = 'body',
  fontWeight = 'normal',
  fontStyle = 'normal',
  level = '1',
  color,
  children,
  className,
}) => {
  const theme: ThemeType = useTheme()
  const Wrapper = tagName as 'span' // ref: https://stackoverflow.com/a/55970164

  const typographyCustomStyle = css`
    font-weight: ${fontWeight};
    font-style: ${fontStyle};
    color: ${color || theme.gray.gray70};
  `

  return (
    <Wrapper
      className={`typography ${className || ''}`}
      css={[typographyBaseStyle, typographyVariantStyle(variant, level), typographyCustomStyle]}
    >
      {children}
    </Wrapper>
  )
}

export default Typography
