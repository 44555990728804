import React, { FC } from 'react'

import { css } from '@emotion/core'
import { useFormikContext } from 'formik'

import Button from 'components/common/atoms/Button'
import InfoMessage from 'components/form/atoms/InfoMessage'
import Label from 'components/form/atoms/Label'

interface SelectButtonGroupProps {
  id?: string
  name: string
  label?: string
  disabled?: boolean
  options: {
    label: string
    value: string | boolean | number
    isDisabled?: boolean
  }[]
}

const selectButtonGroupStyle = css`
  text-align: left;
  margin-bottom: 1.5rem;
  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 -16px;
    @media screen and (min-width: 768px) {
      margin: -16px 0 0 -16px;
    }
  }
`

const selectButtonStyle = css`
  margin: 8px 0 0 16px;
  @media screen and (min-width: 768px) {
    margin: 16px 0 0 16px;
  }
`

const SelectButtonGroup: FC<SelectButtonGroupProps> = ({ label, id, name, disabled = false, options }) => {
  const { setFieldValue, getFieldProps, getFieldMeta } = useFormikContext()
  const { value: fieldVal } = getFieldProps(name)
  const meta = getFieldMeta(name)

  const handleValueClick = (fieldName: string, value: string | number | boolean) => {
    setFieldValue(fieldName, value)
  }
  return (
    <div className="select-button-group" css={selectButtonGroupStyle}>
      {label && <Label id={id} name={name} labelText={label} />}
      <div className="button-wrapper">
        {options.map(({ value, label: optLabel, isDisabled }, idx) => (
          <Button
            type="button"
            kind="select"
            isActive={value === fieldVal}
            css={selectButtonStyle}
            key={`btn-group-${idx}-${value}`}
            onClick={() => handleValueClick(name, value)}
            isDisabled={disabled || isDisabled || false}
          >
            {optLabel}
          </Button>
        ))}
      </div>
      {meta.touched && meta.error ? <InfoMessage text={meta.error} /> : null}
    </div>
  )
}

export default SelectButtonGroup
